import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    infoNums: localStorage.getItem('infoNums') ? localStorage.getItem('infoNums') : 0,
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
  },
  getters: {
    // isLogined(state) {
    //   return state.Authorization ? true : false;
    // },
  },
  mutations,
  actions
})