<template>
  <div class="box font_14">
    <div class="w_1440 location">
      <Breadcrumb>
        <BreadcrumbItem to="/">首页</BreadcrumbItem>
        <BreadcrumbItem>班级详情</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="w_1440 bg_fff w_m20">
      <div class="top_wrapper">
        <div class="top_left">
          <img src="../../assets/image/a@2x.png" />
          <p class="font_16">班级详情</p>
        </div>
      </div>
      <div class="detail_top font_14">
        <div class="detail_block">
          <p>班级名称：</p>
          <p v-if="detailInfo">{{ detailInfo.courseName }}</p>
        </div>
        <div class="detail_block">
          <p>班级类型：</p>
          <p v-if="detailInfo">{{ detailInfo.classTypeName }}</p>
        </div>
        <div class="detail_block">
          <p>教师：</p>
          <p v-if="detailInfo">{{ detailInfo.teacherName }}</p>
        </div>
        <div class="detail_block">
          <p>年度：</p>
          <p v-if="detailInfo">{{ detailInfo.year }}</p>
        </div>
        <div class="detail_block">
          <p>季度：</p>
          <p v-if="detailInfo">{{ detailInfo.quarter }}</p>
        </div>
        <div class="detail_block">
          <p>验证码：</p>
          <p v-if="detailInfo">{{ detailInfo.captcha }}</p>
        </div>
      </div>
      <div class="content">
        <div class="list">
          <div class="list_top flex01">
            <div class="list_top_left flex02">
              <p class="title_circle"></p>
              <div>总分排行榜</div>
            </div>
            <div class="list_top_right">
              <router-link :to="'cartogram?classId=' + classId"><Button type="primary" class="btn_border2 index_m1"
                  ghost>总分统计图</Button></router-link>
              <router-link :to="'Sign?id=' + classId"><Button type="primary" class="btn_border2" ghost>签到统计</Button>
              </router-link>
            </div>
          </div>
          <div class="list_content">
            <Table :columns="columns" :data="data">
              <template slot-scope="{ row, index }" slot="student">
                <router-link :to="'Student?id=' + row.studentId" v-if="loginType == 1 || loginType == 2">
                  {{ row.name }}({{ row.level }})
                </router-link>
                <span class="red1" v-if="loginType == 0">{{ row.name }}({{ row.level }})</span>
              </template>
              <template v-for="(itemChild, indexChild) in 200" slot-scope="{ row, index }"
                :slot="'action' + indexChild">
                {{ row.list[indexChild].score }}
                <span class="" style="
                    width: 4px;
                    height: 4px;
                    background-color: red;
                    border-radius: 50%;
                    display: inline-block;
                    vertical-align: middle;
                  " v-if="row.list[indexChild].isFinish == 0"></span>
              </template>
            </Table>
          </div>
        </div>
      </div>

      <!-- 作业 -->
      <div class="bottom_wrapper">
        <div class="bottom_btn" v-if="loginType == 1 || loginType == 2">
          <Button type="primary" class="btn_border2" @click="get_work('/Layout', {classId: classId,className: detailInfo.courseName})">布置作业</Button>
        </div>
        <Tabs value="name1" @on-click="getCurName">
          <TabPane label="本次作业" name="name1">
            <div class="content" v-if="curList.length > 0 && curName == 'name1'">
              <div class="list" v-for="(item1, index1) in curList" :key="index1">
                <h3 class="work-h3">
                  <span>{{ item1.orders }}</span>
                  <Button type="primary" v-if="loginType == 1 || loginType == 2" class="btn_border2"  @click="getDelWorkInfo(item1.jobId)">取消本次作业</Button>
                </h3>
                <div v-for="(item, listIdx) in item1.list" :key="listIdx">
                  <div class="list_top flex01">
                    <div class="list_top_left flex02">
                      <p class="title_circle"></p>
                      <div>{{ item.groupName }}</div>
                    </div>
                  </div>
                  <div class="list_content">
                    <Table :columns="item.tabHeader" :data="item.list">
                      <template slot-scope="{ row, index }" slot="student">
                        <router-link :to="'Student?id=' + row.student.studentId"
                          v-if="loginType == 1 || loginType == 2">
                          {{ row.studentName }}({{ row.level }})
                        </router-link>
                        <span class="red1" v-if="loginType == 0">{{ row.studentName }}({{ row.level }})</span>
                      </template>
                      <template v-for="(itemChild, indexChild) in 200" slot-scope="{ row }"
                        :slot="'action' + indexChild">
                        <div class="flex02 job_wrapper">
                          <p @click="copyWeight(4, row.list[indexChild])" :class="row.list[indexChild].score != '0.00' ? 'bg1' : ''">
                            {{ row.list[indexChild].score }}
                          </p>
                          <template v-if=" row.list[indexChild].studentId == userId || loginType != 0 ">
                            <p @click="copyWeight(2, row.list[indexChild])" :class=" row.list[indexChild].copyNumber != '0' && !row.list[indexChild].copyNumberEd ? 'bg1' : '' ">抄</p>
                            <p @click="copyWeight(1, row.list[indexChild])" :class="row.list[indexChild].restWork == '1' ? 'bg1' : '' ">重</p>
                            <p @click="copyWeight(3, row.list[indexChild])" class="color_355E7E" :class="row.list[indexChild].notes && row.list[indexChild].notes.length > 0 ? 'bg1' : '' ">笔</p>
                          </template>
                        </div>
                      </template>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="padding: 60px">暂无数据</div>
          </TabPane>
          <TabPane label="历史作业" name="name2">
            <div class="content" v-if="historyList.length > 0 && curName == 'name2'">
              <div class="list" v-for="(item1, index1) in historyList" :key="index1">
                <h3 class="work-h3">
                  <span>{{ item1.orders }}</span>
                  <Button type="primary" class="btn_border2" v-if="loginType == 1 || loginType == 2" @click="getDelWorkInfo(item1.list[0].list[0].list[0].jobId)">删除本次作业</Button>
                </h3>
                <div v-for="(item, listIdx) in item1.list" :key="listIdx">
                  <div class="list_top flex01">
                    <div class="list_top_left flex02">
                      <p class="title_circle"></p>
                      <div>{{ item.groupName }}</div>
                    </div>
                  </div>
                  <div class="list_content">
                    <Table :columns="item.tabHeader" :data="item.list">
                      <template slot-scope="{ row, index }" slot="student">
                        <router-link :to="'Student?id=' + row.student.studentId"
                          v-if="loginType == 1 || loginType == 2">
                          {{ row.studentName }}({{ row.level }})
                        </router-link>
                        <span class="red1" v-if="loginType == 0">{{ row.studentName }}({{ row.level }})</span>
                      </template>
                      <template v-for="(itemChild, indexChild) in 200" slot-scope="{ row }"
                        :slot="'action' + indexChild">
                        <div class="flex02 job_wrapper">
                          <p @click="copyWeight(4, row.list[indexChild])" :class="row.list[indexChild].score != '0.00' ? 'bg1' : ''">
                            {{ row.list[indexChild].score }}
                          </p>
                          <p @click="copyWeight(3, row.list[indexChild])" class="color_355E7E" :class="row.list[indexChild].notes &&row.list[indexChild].notes.length > 0 ? 'bg1' : ''">笔</p>
                          <template v-if="row.list[indexChild].studentId == userId || loginType != 0">
                            <p @click="copyWeight(2, row.list[indexChild])" :class="row.list[indexChild].copyNumber != '0' &&!row.list[indexChild].copyNumberEd? 'bg1': ''">抄</p>
                            <p @click="copyWeight(1, row.list[indexChild])" :class=" row.list[indexChild].restWork == '1' ? 'bg1' : '' "> 重</p>
                          </template>
                        </div>
                      </template>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="padding: 60px">暂无数据</div>
          </TabPane>
        </Tabs>
      </div>
    </div>
    <!-- 重写 -->
    <div v-if="modal1">
      <zxb-again :problemId="copyData.problemId" :studentId="copyData.studentId" @againShow="againShow" />
    </div>

    <!-- 抄写 -->
    <div v-if="modal2">
      <zxb-setcopy :problemId="copyData.problemId" :courseId="copyData.courseId" :studentId="copyData.studentId"
        @setcopy="setcopy" />
    </div>
    <!-- 笔记 -->
    <Modal footer-hide v-model="modal3" title="笔记">
      <div>
        <div class="chaoxie_content flex02">
          <Input v-model="notes" type="textarea" placeholder="" style="width: 100%" />
        </div>
        <div class="flex04 btn_wrapper">
          <Button type="primary" size="large" class="btn_border" ghost @click="modal3 = false">取消</Button>
          <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px"
            @click="studentNotesSubmit()">提交</Button>
        </div>
      </div>
    </Modal>
    <!-- 历史记录 -->
    <Modal footer-hide v-model="modal4" title="历史记录" class-name="historyBox">
      <div>
        <Table :columns="historyTh" :data="historyTd"></Table>
      </div>
    </Modal>
    <Modal title="提示" @on-ok="deleteWork(delWorkId)" v-model="delWorkShow" class-name="vertical-center-modal">
      <div style="font-size: 14px; line-height: 1.6; padding: 20px;">删除后将不能恢复，确定删除此次做作业吗?</div>
    </Modal>
  </div>
</template>
<style type="text/css">
  textarea.ivu-input {
    min-height: 200px !important;
  }
</style>
<script>
  let thisTime, historyTime;
  import {classInfo, classProblem, classStudent, queryClassJob,queryClassJobs,classInfoApi,getByTypeApi,thisWorkApi,historyWorkApi,studentNotesQueryApi,setRestWorkApi,setCopyNumberApi,showToast,studentNotesSubmitApi,getNotesApi,getScoreByApi,deleteWorkApi} from "@/api/index.js";
  import zxbAgain from "@/components/again.vue";
  import zxbSetcopy from "@/components/setcopy.vue";
  export default {
    components: {
      zxbAgain,
      zxbSetcopy,
    },
    data() {
      return {
        data: [],
        loginType: localStorage.getItem("loginType"),
        classId: "",
        detailInfo: null,
        columns: [{
            width: 90,
            title: "排名",
            key: "number",
            fixed: "left",
            className: "table-head-style",
          },
          {
            width: 150,
            title: "学员名称",
            key: "name",
            slot: "student",
            fixed: "left",
            className: "table-head-style",
          },
          {
            width: 160,
            title: "总分(--)",
            key: "score",
            className: "table-head-style",
          },
        ], // 总分排行榜

        value: "",
        modal1: false, //重置
        modal2: false, //抄写
        modal3: false, //笔记
        modal4: false, //历史记录
        formItem: {},

        columns2: [{
            width: 90,
            title: "课程",
            key: "courseIdx",
            fixed: "left",
            className: "table-head-style",
          },
          {
            width: 150,
            title: "姓名",
            key: "studentName",
            fixed: "left",
            slot: "student",
            className: "table-head-style",
          },
          {
            width: 160,
            title: "总得分",
            key: "total",

            className: "table-head-style",
          },
        ],

        classJobList: [], //习题组列表
        classJobOldList: [], //历史作业
        classJobLen: "",
        quarterList: [], //季节列表
        curList: [], //本次作业
        historyList: [], //历史作业
        copyData: {},
        copyNumber: null,
        notes: "", //笔记内容
        userId: localStorage.getItem("userId"),
        curName: "",

        historyTh: [{
            title: "分数",
            key: "score",
          },
          {
            title: "提交时间",
            key: "createTime",
          },
        ],
        historyTd: [],
        studentHisList: [],
        delWorkShow:false, //删除作业提示框
        delWorkId:'', //删除作业的id
      };
    },
    destroyed() {
      clearInterval(historyTime);
      clearInterval(thisTime);
    },
    mounted() {
      this.getCon();
      this.getThisWork();
      this.getHistoryWork();
      this.getCurName("name1");
    },

    methods: {
      getDelWorkInfo(id){
        this.delWorkShow = true;
        this.delWorkId = id;
        console.log('deleteWork',id)
      },
      //删除作业
      deleteWork(){
        let data = {jobId:this.delWorkId}
        console.log('deleteWork',data)
        deleteWorkApi(data).then(res=>{
          // console.log('resresresres',res)
          this.$Message.success('删除成功');
          if (this.curName === "name1"){
            this.getThisWork();
          }else{
            this.getHistoryWork();
          }
        })
      },
      getCurName(e) {
        if (this.curName == e) return;
        this.curName = e;
        clearInterval(historyTime);
        clearInterval(thisTime);
        if (e === "name1") {
          thisTime = setInterval(() => {
            this.getThisWork();
          }, 3000);
        } else {
          historyTime = setInterval(() => {
            this.getHistoryWork();
          }, 3000);
        }
      },
      studentNotesSubmit() {
        // if(this.notes.length === 0) return showToast(this,'笔记内容不能为空')
        let data = {
          studentId: this.copyData.studentId,
          classId: this.classId,
          problemId: this.copyData.problemId,
          notes: this.notes,
        };
        studentNotesSubmitApi(data).then((res) => {
          showToast(this, "笔记提交成功", 1);
          this.getCon();
          this.modal3 = false;
        });
      },
      copyWeight(index, item) {
        // 1-重写，2-抄，3-笔记，4-分数新页面
        this.copyData = item;
        this.copyNumber = item.copyNumber == 0 ? "" : item.copyNumber;
        //老师 or 超级管理员 为学员设置抄写和重写 1-2, 0---学生
        if (index === 4) {
          let studentId = localStorage.getItem('userId');
          if(studentId == item.studentId && this.loginType == 0 || this.loginType != 0){
            const routeUrl = this.$router.resolve({
              path: "/taskDetail",
              query: {
                studentId: item.studentId,
                courseId: item.courseId,
                studentScoreId: item.id,
                classId: this.classId,
                title: item.title,
                problemId: item.problemId,
              },
            });
            window.open(routeUrl.href, "_blank");
          }
        } else {
          if (index === 3) {
            this.getNotes();
            this["modal" + index] = true;
          }

          if (this.loginType != 0) {
            this["modal" + index] = true;
          }
        }
      },
      // 获取学员笔记
      getNotes() {
        getNotesApi({
          problemId: this.copyData.problemId,
          studentId: this.copyData.studentId,
          courseId: this.copyData.courseId,
        }).then((res) => {
          this.notes = res.notes;
          // this.getCon()
        });
      },
      getCon(type = 1) {
        let classId = this.$route.query.classId;
        this.classId = classId;
        if ((type = 1)) {
          // 获取顶部信息
          getByTypeApi({
            type: "QUARTER",
          }).then((res) => {
            this.quarterList = res;
          });
          classInfoApi({
            classId: classId,
          }).then((res) => {
            this.detailInfo = res;
            let a_ = [];
            let data = [];
            let topList = res.topList;
            if (topList.length > 0) {
              topList.forEach((n, i) => {
                data = data.concat({
                  number: i + 1,
                  name: n.student.studentName,
                  level: n.student.level,
                  studentId: n.student.studentId,
                  score: n.student.total,
                  list: n.list,
                });
              });
              topList[0].list.forEach((n, k) => {
                a_.push({
                  width: 200,
                  title: n.problemGroupName,
                  slot: "action" + k,
                  className: "table-head-style",
                });
              });
            }
            // 最后一行补齐剩下的宽度
            a_.push({
              title: " ",
              className: "table-head-style",
            });

            this.data = data;
            this.columns = this.columns.concat(a_);
            data.forEach((m) => {
              m.isFinish = 0;
              m.list.map((n) => {
                n.isFinish = n.isFinish || 1;
                return m;
              });
            });
          });
        }
      },
      // 本次作业
      getThisWork() {
        thisWorkApi({
          classId: this.classId,
        }).then((res) => {
          this.getNewList(res, "curList");
        });
      },
      // 历史作业
      getHistoryWork() {
        historyWorkApi({
          classId: this.classId,
        }).then((res) => {
          this.getNewList(res, "historyList");
        });
      },
      getNewList(res, name) {
        let columns2 = this.columns2;
        //每节课每道题的id
        res.map((item1, index) => {
          item1.list.map((m, index2) => {
            m.tabHeader = columns2;
            m.list.map((listItem, listIdx) => {
              // studentScoreId 1620684784483201025
              // userId 1593130700595277826  listItem.student.studentId ===localStorage.getItem("userId")
              if(listItem.student.studentId == localStorage.getItem("userId")){
                listItem.list.map((newItem, newIdx) => {
                  // console.log('newItem===>', newItem.studentName,newItem.id)
                  m.tabHeader = m.tabHeader.concat({
                    width: 200,
                    title: newItem.title,
                    slot: "action" + newIdx,
                    className: "table-head-style cur hover",
                    renderHeader: (h, params) => {
                      return h("div", [
                        h(
                          "span", {
                            on: {
                              click: (event) => {
                                window.open(`#/topicDetail?studentId=${newItem.studentId}&courseId=${newItem.courseId}&studentScoreId=${newItem.id}&setId=${newItem.problemId}&classId=${this.classId}`, "_blank");
                              },
                            },
                          },
                          newItem.title
                        ),
                      ]);
                    },
                  });
                });
                              
              }
              //非学员身份
              if(localStorage.getItem("loginType") != 0){
                if(listIdx === 0){
                  listItem.list.map((newItem, newIdx) => {
                    m.tabHeader = m.tabHeader.concat({
                      width: 200,
                      title: newItem.title,
                      slot: "action" + newIdx,
                      className: "table-head-style cur hover",
                      renderHeader: (h, params) => {
                        return h("div", [
                          h(
                            "span", {
                              on: {
                                click: (event) => {
                                  window.open(`#/topicDetail?studentId=${localStorage.getItem("userId")}&courseId=${newItem.courseId}&studentScoreId=${newItem.id}&setId=${newItem.problemId}&classId=${this.classId}`, "_blank");
                                },
                              },
                            },
                            newItem.title
                          ),
                        ]);
                      },
                    });
                  });
                   
                }
              }
              if(listIdx === listItem.list.length-1){
                m.tabHeader.push({
                  title: " ",
                  className: "table-head-style",
                });
              }
              
              listItem.courseIdx = listIdx + 1;
              listItem.studentName = listItem.student.studentName;
              listItem.level = listItem.student.level;
              listItem.total = listItem.student.total;
              return listItem;
            });
          });
          return item1;
        });
        this[name] = res;
        // console.log('res===>',res)
      },
      fillTimuTopLink(){
        
      },
      // 跳转页面
      get_work(a, b) {
        this.$publicFun.get_link(a, b);
      },

      getTen(m) {
        return m > 9 ? (m = m) : (m = "0" + m);
      },
      //重写
      againShow(e) {
        this.modal1 = false;
        // console.log("111111sad66656");
      },
      setcopy() {
        // console.log("setcopysad66656");
        this.modal2 = false;
        this.getCon();
      },
      get_ok1(copyNumber, type) {
        if (type === 1 && !copyNumber) return showToast(this, "抄写次数不能为空");
        if (type === 1 && copyNumber == 0)
          return showToast(this, "抄写次数不能为0");
        let data = {
          problemId: this.copyData.problemId,
          copyNumber: copyNumber,
          studentId: this.copyData.studentId,
          courseId: this.copyData.courseId,
        };
        setCopyNumberApi(data).then((res) => {
          showToast(this, "抄写设置成功", 1);
          this.copyNumber = null;
          this.getCon();
        });
        this.modal2 = false;
      },
    },
  };
</script>

<style lang="less" scoped>
  .list /deep/.ivu-table table{min-width: 100%;}
  .work-h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }

  .index_m1 {
    margin-right: 20px;
  }

  .top_wrapper {
    display: flex;
    height: 59px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
  }

  .top_left {
    display: flex;
    align-items: center;

    img {
      width: 19px;
      height: 19px;
      margin: 0 10px 0 24px;
    }
  }

  .top_right {
    padding-right: 130px;
  }

  .detail_top {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;
    border-bottom: 1px solid #ebeef5;
  }

  .detail_block {
    flex: 0 0 25%;
    display: flex;
    margin: 10px 0;
    padding-left: 27px;
    box-sizing: border-box;
  }

  .occupied {
    color: #fff;
  }

  .content {
    padding: 0 14px;
    overflow: hidden;
  }

  .list {
    margin: 18px 0;
    border: 1px solid #f5f7fa;
  }

  .list_top {
    height: 48px;
    padding-right: 115px;
    border-bottom: 1px solid #f5f7fa;
    background-color: rgba(245, 247, 250, 0.4);
  }

  .list_top_left {
    flex: 1;
  }

  .list_top_left div {
    margin-right: 26px;
  }

  .title_circle {
    width: 5px;
    height: 5px;
    margin: 0 8px 0 13px;
    border-radius: 50%;
    background-color: #355e7e;
  }

  .title_line {
    width: 3px;
    height: 13px;
    border-radius: 4px;
    margin: 0 7px 0 34px;
    background-color: #355e7e;
  }

  .list_content {
    padding: 0 0 0 17px;
    margin-bottom: 16px;
  }

  .video_wrapper {
    width: 100%;
  }

  .box /deep/ .ivu-form .ivu-form-item-label {
    padding: 11px 8px 11px 0;
  }

  .box /deep/ .ivu-form-item {
    margin-bottom: 0;
  }

  .box /deep/ .ivu-form-item-content {
    line-height: 36px;
    font-size: 0;
  }

  .box /deep/ .ivu-select-single .ivu-select-selection {
    height: 36px;
  }

  .bottom_wrapper .bottom_btn {
    right: 20px !important;
    top: -6px !important;
  }

  .box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
  .box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
    height: 34px;
    line-height: 34px;
  }

  .box /deep/ .ivu-input {
    height: 36px;
  }

  /deep/ .ivu-table th.table-head-style {
    padding: 12px 0;
    background-color: #ffffff;
  }

  /deep/ .ivu-table th.lan {
    color: #355e7e;
    cursor: pointer;
  }

  .box /deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
    color: #355e7e;
  }

  .box /deep/ .ivu-tabs-ink-bar {
    background-color: #355e7e;
  }

  .box /deep/ .ivu-tabs-nav .ivu-tabs-tab:hover {
    color: #355e7e;
  }

  .box /deep/ .ivu-tabs-nav-scroll {
    padding: 0 0 0 10px;
  }

  .box /deep/ .ivu-tabs-bar {
    margin-bottom: 0;
    border-color: #f5f7fa;
  }

  .box /deep/ .ivu-tabs-nav .ivu-tabs-tab {
    padding-top: 0;
    padding-bottom: 16px;
  }

  .bottom_wrapper {
    position: relative;

    .bottom_btn {
      top: 0;
      right: 130px;
      z-index: 999;
      position: absolute;
    }
  }

  .wrapper_title {
    margin: 18px 0 0;
    padding-left: 26px;
  }

  .job_wrapper {
    p {
      cursor: pointer;
      margin-right: 8px;

      // &:first-child {
      //   width: 40px;
      // }
    }
  }

  .chongxie_wrapper /deep/ .ivu-modal-header {
    border: none;
  }

  .btn_wrapper {
    margin: 38px 0 0 0;
  }

  /deep/.historyBox .ivu-modal-body {
    height: 60vh;
    overflow-y: auto;
  }
</style>
