/* 同步函数，直接更新state */
const SAVE_INFO = 'SAVE_INFO';
const SAVE_USER_INFO = 'SAVE_USER_INFO';

export default {
  // 存储未读消息个数
  [SAVE_INFO](state, infoNums) {
    state.infoNums = infoNums;
  },
  // 存储用户信息
  [SAVE_USER_INFO](state, userInfo) {
    state.userInfo = userInfo;
  } 
}