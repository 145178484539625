import Vue from "vue";
import VueRouter from "vue-router";
import Reg from "../views/Login/reg.vue"; // 注册
import Password from "../views/Login/password.vue"; // 修改密码
import Note from "../views/Home/note.vue"; // 笔记
import Home from "../views/Home/index.vue"; // 首页
import Fwb from "../views/Home/fwb.vue"; // 使用说明
import Login from "../views/Login/login.vue"; // 登录
import Sign from "../views/Home/sign.vue"; // 签到统计
import Detail from "../views/Home/detail.vue"; // 详情
import Copy from "../views/Home/copy.vue"; // 抄写管理
import Info from "../views/Home/info.vue"; // 消息中心
import Send from "../views/Home/send.vue"; // 发送信息
import Layout from "../views/Home/layout.vue"; // 布置作业 *
import Student from "../views/Home/student.vue"; // 学员主页
import Cartogram from "../views/Home/cartogram.vue"; // 统计图
import EditTopic from "../views/Home/edit_topic.vue"; // 编辑题目
import gradeDetail from "../views/Home/grade_detail.vue"; // 分数详情
import EditChoose from "../views/Home/edit_choose.vue"; // 编辑选择填空
import EditAnswer from "../views/Home/edit_answer.vue"; // 编辑标准答案
import TopicDetail from "../views/Home/topic_detail.vue"; // 题目详情 (抄写)
import taskDetail from "../views/Home/task_detail.vue"; // 题目详情 (抄写)

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Detail",
    name: "Detail",
    component: Detail,
  },
  {
    path: "/TopicDetail",
    name: "TopicDetail",
    component: TopicDetail,
  },
  {
    path: "/taskDetail",
    name: "taskDetail",
    component: taskDetail,
  },
  {
    path: "/Fwb",
    name: "Fwb",
    component: Fwb,
  },
  {
    path: "/Cartogram",
    name: "Cartogram",
    component: Cartogram,
  },
  {
    path: "/Student",
    name: "Student",
    component: Student,
  },
  {
    path: "/Sign",
    name: "Sign",
    component: Sign,
  },
  {
    path: "/Copy",
    name: "Copy",
    component: Copy,
  },
  {
    path: "/Info",
    name: "Info",
    component: Info,
  },
  {
    path: "/Send",
    name: "Send",
    component: Send,
  },
  {
    path: "/Note",
    name: "Note",
    component: Note,
  },
  {
    path: "/Layout",
    name: "Layout",
    component: Layout,
  },
  {
    path: "/EditTopic",
    name: "EditTopic",
    component: EditTopic,
  },
  {
    path: "/EditChoose",
    name: "EditChoose",
    component: EditChoose,
  },
  {
    path: "/gradeDetail",
    name: "gradeDetail",
    component: gradeDetail,
  },
  {
    path: "/EditAnswer",
    name: "EditAnswer",
    component: EditAnswer,
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/Reg",
    name: "Reg",
    component: Reg,
  },
  {
    path: "/Password",
    name: "Password",
    component: Password,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
