import axios from 'axios';
import $publicFun from "../assets/js/public.js";
// iview组件
import { Message } from "view-design";
let baseurl
if (process.env.NODE_ENV === 'development') {
	// 本地
	baseurl = 'http://118.24.31.25:8123/';
} else {
		// 线上
		baseurl = '/';
}
//创建axios的一个实例
var instance = axios.create({
    baseURL: baseurl,
    timeout: 30000,
});

let pending = [];
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
    let tokenName = localStorage.getItem("tokenName")
    config.headers[tokenName] = localStorage.getItem("tokenValue") || "";
    if (!config.notLoading) {
        if (pending.some(item => item.url === config.url)) {
            pending.find(v => v.url === config.url).loading = true;
        } else {
            pending.push({
                url: config.url,
                loading: true
            });
        }
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
  if(response.data.errorCode == 401){
    Message.error({content:response.data.errorMsg});
    setTimeout(() => {
        $publicFun.get_link("/Login", {});
      }, 1000);
    return Promise.reject(response.data.errorMsg);
  }
  if(!response.data.success){
    if(document.getElementsByClassName('ivu-message')[0]){
      document.getElementsByClassName('ivu-message')[0].innerHTML=''
    }
    Message.error({content:response.data.errorMsg || response.data.message});
    return Promise.reject(response.data.errorMsg || response.data.message);
  }
  
  if(response.data.success === true){
    if(document.getElementsByClassName('ivu-message')[0]){
      document.getElementsByClassName('ivu-message')[0].innerHTML=''
    }
    // let results = response.data.result
    // if(results === false){
    //   results = 
    // }
    return response.data.result
  }
}, function (error) {
    // 对响应错误做点什么
    
    if (error.config && !error.config.notLoading) {
        pending.find(v => v.url === error.config.url).loading = false;
    }
    
    if(document.getElementsByClassName('ivu-message')[0]){
      document.getElementsByClassName('ivu-message')[0].innerHTML=''
    }else{
      // console.log('没有弹窗')
    }
    return Promise.reject(error);
});
export default function (method, url, data = null, options = {}) {
    method = method.toLowerCase();
    if (method == 'post') {
      return instance.post(url, data, options);
    } else if (method == 'get') {
      return instance.get(url,{ params: data, ...options });
    } else if (method == 'delete') {
      return instance.delete(url, { params: data, ...options });
    } else if (method == 'put') {
      return instance.put(url, data, options);
    } else {
      console.error('未知的method' + method);
      return false;
    } 
  }