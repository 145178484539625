<template>
  <div class="box">
    <div class="w_1440 clearfix">
      <div class="task_detail">
        <dl class="w1">
          <dd><a :href="'#/Detail?classId='+linkIds.courseId"><Button type="primary"  size="large" class="btn_border">题目</Button></a></dd>
          <dd v-if="loginType !=0 && linkIds.form != 2" @click="modal2=true"><Button size="large" class="btn_border">抄写</Button></dd>
          <dd v-if="loginType !=0 && linkIds.form != 2" @click="modal1=true"><Button size="large" class="btn_border">重写</Button></dd>
        </dl>
        <div class="w2">
          <!-- window.open(, "_blank"); -->
          <div class="tit" @click="link">{{title}}</div>
          <pre class="pre">{{content}}</pre>
        </div>
        <div class="w3">
          <Table border :columns="columns" :data="data" v-if="data.length > 0">
            <template slot-scope="{ row, index }" slot="action">
            	<div class="cur" @click="getContent(row,index)" :class="{on:index === cur}">{{row.score}}</div>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <!-- 重写 -->
    <div v-if="modal1">
      <zxb-again :problemId="linkIds.problemId" :studentId="linkIds.studentId" @againShow="againShow" />
    </div>
    
    <!-- 抄写 -->
    <div v-if="modal2">
      <zxb-setcopy :problemId="linkIds.problemId" :courseId="linkIds.courseId" :studentId="linkIds.studentId" @setcopy="setcopy" />
    </div>
  </div>
</template>

<script>
  import { resolveComponent } from 'vue'
import * as echarts from "echarts";
import {workHistoryApi} from "@/api/index.js";
import zxbAgain from '@/components/again.vue'
import zxbSetcopy from '@/components/setcopy.vue'
let timeRun = null;
let chaoxieTimeRun
export default {
  components:{zxbAgain,zxbSetcopy},
  data() {
    return {
      modal1:false,
      modal2:false,
      loginType:localStorage.getItem('loginType'),
      content:'',
      columns: [
        {
          width: 90,
          title: "分数",
          key: "score1",
          slot: 'action',
          className: "table-head-style"
        },
        {
          title: "提交时间",
          key: "createTime",
          className: "table-head-style",
        },
      ],
      data: [],
      cur:0,
      title:'',
      linkIds:{}
    };
  },
  mounted() {
    let links = this.$route.query;
    this.linkIds = links
    this.title = this.linkIds.title;
    workHistoryApi({
      studentId:links.studentId,
      studentScoreId:links.studentScoreId
    }).then(res=>{
      
      if(links.from == 2){
        this.data = res.filter((n, i) => i > 9);
      }else{
        this.data = res;
      }
      if(res && res.length > 0){
        this.content = this.data[0].content
      }
      
    })
		
  },
  //销毁
  destroyed() {
    
  },
  methods: {
    link(){
      let linkIds = this.linkIds;
      window.location.href=`#/topicDetail?studentId=${linkIds.studentId}&courseId=${linkIds.courseId}&studentScoreId=${linkIds.studentScoreId}&setId=${linkIds.problemId}&classId=${linkIds.classId}`
    },
    //重写
    againShow(e){
      this.modal1 = false;
      console.log('111111sad66656')
    },
    setcopy(){
      console.log('setcopysad66656')
      this.modal2 = false;
    },
      getContent(item,index){
        this.content = item.content;
        this.cur = index;
      },
      linkto(){
        this.$router.push({
          path:'/taskDetail',
          query:{
            studentId:this.linkIds.studentId,
            courseId:this.linkIds.courseId,
            studentScoreId:this.linkIds.studentScoreId,
            setId:this.linkIds.problemId,
            classId:this.linkIds.classId
          }
        })
      },
  },
  
};
</script>

<style lang="less" scoped>
.task_detail{display: flex; padding: 50px 0;}
.task_detail .w1 dd{margin-bottom: 10px;}
.task_detail .w2{width: 50%; margin: 0 20px;}
.task_detail .pre{width: 100%; height: 600px; border: 1px solid #ddd;background-color: #fff; padding: 20px; font-size: 16px; color: #555;}
.task_detail .w3{width: 30%;}
.task_detail .tit{font-size: 18px; margin-bottom: 10px;cursor: pointer;}
.task_detail .on{color: #57a3f3;}
.task_detail .cur{position: absolute;left: 0;top: 0; display: flex; align-items: center;width: 100%; height: 100%;padding-left: 18px;}
.task_detail /deep/td{position: relative;}
</style>
