<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="编辑题目"></Title>
      <Form ref="ruleForm" :model="ruleForm" :label-width="80" id="ruleForm">
        <FormItem label="">
          <!-- <div class="edit_m1">
            <Button type="primary" size="large" class="btn_border" ghost>+添加数据</Button>
            <Button type="primary" size="large" class="btn_border" style="margin-left: 10px" ghost @click="modal_show = true">+添加链接</Button>
          </div> -->
        </FormItem>
        <FormItem label="标题" prop="title" class="haveto">
          <Input type="text" v-model="ruleForm.title"></Input>
        </FormItem>
        <FormItem label="描述" prop="notes" class="haveto">
          <div id="notes" ref="notes"><div v-html="ruleForm.notes"></div></div>
        </FormItem>
        <FormItem label="输入描述" prop="inputNotes" class="haveto">
          <div id="inputNotes" ref="inputNotes"><div v-html="ruleForm.inputNotes"></div></div>
        </FormItem>
        <FormItem label="输出描述" prop="outNotes" class="haveto">
          <div id="outNotes" ref="outNotes"><div v-html="ruleForm.outNotes"></div></div>
        </FormItem>
        <FormItem label="内存" prop="memory">
          <Input type="text" v-model="ruleForm.memory"></Input>
        </FormItem>
        <FormItem label="时间限制" prop="timeLimit">
          <Input type="text" v-model="ruleForm.timeLimit"></Input>
        </FormItem>
        <template v-for="(item, index) in inputOutList">
          <FormItem :label="'样例'+(index+1)" class="edit-h4"></FormItem>
          <Row :gutter="16">
            <Col span="12">
              <FormItem label="输入">
                <div :id="'input'+index" :ref="'input'+index"><div v-html="item.input"></div></div>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="输出">
                <div :id="'out'+index" :ref="'out'+index"><div v-html="item.out"></div></div>
              </FormItem>
            </Col>
          </Row>
        </template>
        <div class="list_btn">
          <Button type="primary" size="large" class="btn_border" @click="addInputOut" ghost>+添加</Button>
        </div>
        <FormItem label="提示">
          <div id="hint" ref="hint"><div v-html="ruleForm.hint"></div></div>
        </FormItem>
        <FormItem label="评分链接" class="edit-h4"></FormItem>
        <FormItem label="网站链接">
          <Input type="text" v-model="ruleForm.scoreLinks"></Input>
        </FormItem>
        <FormItem label="题目名称">
          <Input type="text" v-model="ruleForm.scoreTitle"></Input>
        </FormItem>
        <FormItem>
          <Upload :action="uploadUrl" :on-success="uploadFile">
            <Button icon="ios-cloud-upload-outline">+添加数据</Button>
          </Upload>
        </FormItem>
        
        <div class="flex04 edit_submit_btn">
          <Button type="primary" size="large" class="btn_bg" @click="submit('ruleForm')">提交</Button>
        </div>
      </Form>
    </div>
    <!-- 添加链接 -->
    <Modal footer-hide v-model="modal_show" title="添加链接">
      <div>
        <div class="chaoxie_content flex02">
          <p>添加链接：</p>
          <div style="flex: 1">
            <Input v-model="value" placeholder="" style="width: 100%" />
          </div>
        </div>
        <div class="flex04 btn_wrapper">
          <Button type="primary" size="large" class="btn_border" ghost @click="modal_show = false" >取消</Button>
          <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px" @click="submit('ruleForm')">添加</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
  
  <script>
  import {newProblemSetsApi,uploadApi1,editorCreate,problemDetailApi,showToast} from "@/api/index.js"
  export default {
    data() {
      return {
        uploadUrl:uploadApi1(),
        content:'1111',
        subId:this.$route.query.id,
        value: "",
        modal_show: false,
        title_text: "",
        import_text: "",
        
        inputOutList:[{input:'',out:''}], //输入描述
  
        ruleForm: {
          title: '',
          notes:'',
          inputNotes: '',
          outNotes:'',
          memory:'',
          timeLimit:'',
          hint: '',
          groupId: '',
          scoreLinks: '',
          scoreTitle: '',
          dataLink:'',
          problemSamples:[]
        },
        // rules: {
        //   title: [
        //     { required: true, message: '', trigger: 'blur' },
        //     { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        //   ],notes: [
        //     { required: true, message: '请填写描述', trigger: 'change' }
        //   ],inputNotes: [
        //     { required: true, message: '请填写输入描述', trigger: 'change' }
        //   ],outNotes: [
        //     { type: 'string', required: true, message: '请填写输出描述', trigger: 'change' }
        //   ]
        // },
      };
    },
    mounted() {

      problemDetailApi({problemId:this.subId}).then(res=>{
        console.log(res)
        if(res){
          this.ruleForm = {
            id:this.subId,
            title: res.title,
            notes:res.notes,
            inputNotes: res.inputNotes,
            outNotes: res.outNotes,
            memory:res.memory,
            timeLimit:res.timeLimit,
            hint: res.hint,
            groupId: res.groupId,
            scoreLinks: res.scoreLinks,
            scoreTitle: res.scoreTitle,
            problemSamples:[],
            dataLink:res.dataLink
          }
          let problemSamples = res.problemSamples;
          let inputOutList = [];
          problemSamples.forEach((m,index) => {
            if(m.sampleInput.length > 0 || m.sampleOut.length > 0){
              inputOutList = inputOutList.concat({input:m.sampleInput,out:m.sampleOut});
            }
          })
          this.inputOutList = inputOutList;
          setTimeout(()=>{
            for(let i=0; i<inputOutList.length; i++){
              this.editorAdd(i)
            }
          },100)
        }
        if(res && res.problemSamples.length === 0 || !res){
          let inputOutList = this.inputOutList;
          let len = inputOutList.length === 0 ? 0 : inputOutList.length-1;
          this.editorAdd(len)
        }
      })
      editorCreate(this,'notes')
      editorCreate(this,'inputNotes')
      editorCreate(this,'outNotes')
      editorCreate(this,'hint')
			
      
    },
    methods: {
      addInputOut(){
        this.inputOutList = this.inputOutList.concat({input:'',out:''})
        let len = this.inputOutList.length === 0 ? 0 : this.inputOutList.length-1;
        if(len < 10){
          setTimeout(()=>{
            this.editorAdd(len);
          },10)
        }
        
        
      },
      editorAdd(i){
        console.log(i)
        let inputId = 'input'+i;
        editorCreate(this,inputId)
        let outId = 'out'+i;
        editorCreate(this,outId)
      },
      // 获取编辑框内容
      getHtml(idName){
        let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0]
        return text ? text.innerHTML : ''
      },
      // 获取编辑框文本
      getText(idName){
        let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0];
        return text ? text.innerText : '';
      },
			// 字符串为空或全部为空格，返回true；否则，返回false
			isNull( idName ){
				
				let str = this.getText(idName).replace(/\n/g,'')
				if ( str == "" ) return true;
				var regu = "^[ ]+$";
				var re = new RegExp(regu);
				console.log(idName,re.test(str))
				return re.test(str);
			},
      // 提交编辑
      submit(name) {
        let data = JSON.parse(JSON.stringify(this[name]));
        data.notes = this.getHtml('notes')
        data.inputNotes = this.getHtml('inputNotes')
        data.outNotes = this.getHtml('outNotes')
        data.notes = this.getHtml('notes')
        data.hint = this.getHtml('hint');
        let problemSamples = [];
        for(let i = 0; i < this.inputOutList.length; i++){
          if(this.getText('input'+i).length > 0 || this.getText('out'+i).length > 0){
            problemSamples = problemSamples.concat({
              sampleInput: this.getHtml('input'+i),
              sampleOut: this.getHtml('out'+i)
            })
          }
        }
        data.problemSamples = problemSamples;
				if(data.title.length === 0) return showToast(this,'标题不能为空');
				if(this.isNull('notes')) return showToast(this,'描述不能为空');
				if(this.isNull('inputNotes')) return showToast(this,'输入描述不能为空');
				if(this.isNull('outNotes')) return showToast(this,'输出描述不能为空');
				
        this.$refs[name].validate((valid) => {
          if (valid) {
            newProblemSetsApi(data).then(res=>{
              showToast(this,'编辑成功',1);
            })
          } else {
            // this.$Message.error('Fail!');	
          }
        })
      },
      // 上传图片
      uploadFile(e){
        this.ruleForm.dataLink = e.result.path
        console.log(e)
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
    #ruleForm{
      padding:0 30px 20px; max-width: 100%;
      /deep/ .ivu-input-wrapper{max-width: 500px;}
      .edit_m1{margin-left: 0}
      /deep/ .edit-h4 label{font-weight: bold;color: #333}
      .list_btn{margin-left: 80px !important;border-top: 1px solid #ededed;border-bottom: 1px solid #ededed; margin: 40px 0;width: auto}
      .edit_submit_btn{width: 100%;}
    }
		.haveto /deep/ label::before{content: '*'; color: red; margin-right: 5px;}
  .edit_m1 {
    margin-top: 20px;
    margin-left: 55px;
  }
  
  .edit_m2 {
    margin: 12px 0;
  }
  
  .edit_list {
    margin: 20px 0;
  }
  
  .edit_line {
    width: 100%;
    height: 1px;
    background-color: #ebeef5;
  }
  
  .label {
    width: 97px;
    line-height: 36px;
    text-align: right;
    margin-right: 11px;
  }
  
  .text_wrapper {
    max-width: 820px;
  }
  
  .text_wrapper /deep/ .ivu-input {
    height: 36px;
  }
  
  .delete_icon {
    width: 28px;
    height: 28px;
    display: block;
    cursor: pointer;
    background: url(../../assets/image/n.png) no-repeat center;
    background-size: 18px 18px;
  }
  
  .list_wrapper {
    width: 928px;
    overflow: hidden;
  }
  
  .list_line {
    width: 820px;
    margin-top: 20px;
    margin-left: 108px;
    height: 1px;
    background-color: #ebeef5;
  }
  
  .list_title {
    margin: 11px 0 0 51px;
  }
  
  .text_class {
    /deep/ textarea {
      resize: none;
    }
  }
  
  .list_btn {
    width: 820px;
    padding: 20px 0;
    margin-left: 108px;
    border-bottom: 1px solid #ebeef5;
  }
  
  .edit_submit_btn {
    width: 928px;
    margin-bottom: 73px;
  }
  
  .box /deep/ .w-e-toolbar,
  .box /deep/ .w-e-text-container {
    z-index: 99 !important;
  }
  
  .btn_wrapper {
    margin: 38px 0 0 0;
  }
  </style>
  