<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="抄写管理"></Title>
      <div class="content">
        <Table border height="600" :columns="columns1" :data="data1"></Table>
      </div>
    </div>
  </div>
</template>

<script>
import { copyListApi,getConfByKeyApi} from "@/api/index.js";
export default {
  data() {
    return {
      columns1: [
        {
          width: 90,
          title: "序号",
          key: "xuhao",
          fixed: "left",
          className: "table-head-style",
        },
        {
          width: 150,
          title: "学员姓名",
          key: "studentName",
          fixed: "left",
          className: "table-head-style",
        },
      ],
      data1: [],

      copyAllNum:2, //抄写总数
    };
  },
  mounted() {
    
    getConfByKeyApi({key:'COPY_NUMBER'}).then(res=>{
      this.copyAllNum = res.content
    })
    this.getList();
  },
  methods: {
    getList(){
      copyListApi({}).then(res=>{
        this.data1 = res.map((m,index) => {
          m.xuhao = index+1;
          return m
        });
        let arr = res.map(m => {return m.list.length})
        let maxNum = arr.indexOf(Math.max(...arr))
        res.forEach((item, itemIdx) => {
          item.list.forEach((subItem,subIdx) => {
            subIdx = subIdx+1;
            item['title'+subIdx] = subItem.title;
            item['num'+subIdx] = `${subItem.copyNum}/${this.copyAllNum}`;
            console.log(itemIdx)
            if(itemIdx === maxNum){
              console.log()
              this.columns1.push({
                title: '题目'+subIdx,
                width: 160,
                key: 'title'+subIdx,
                className: "table-head-style",
              })
              this.columns1.push({
                width: 80,
                title: '情况',
                key: 'num'+subIdx,
                className: "table-head-style",
              })
            }
            
          })
        });
      })
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 20px 14px;
}
/* /deep/ .ivu-table table{width: 100% !important;} */
/deep/ .ivu-table th.table-head-style {
  padding: 12px 0;
  background-color: #ffffff;
}
</style>
