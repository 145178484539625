<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="详情"></Title>
      <div class="content flex05">
        <div class="flexW1">
          <div class="wrapper hidden">
            <p class="font_16 title_text">题目名称</p>
            <div class="flex06">
              <div class="flexW1">
                <Input v-model="value" type="textarea" :rows="11" placeholder=""/>
              </div>
              <div class="grade_m1">
                <Button type="primary" size="large" class="btn_border" ghost>题目</Button>
                <Button type="primary" size="large" class="btn_border" ghost>抄写</Button>
                <Button type="primary" size="large" class="btn_border" ghost>重写</Button>
              </div>
            </div>
          </div>
        </div>
        <div class="flexW1">
          <div class="wrapper">
            <p class="font_16 title_text">历史记录</p>
            <div>
              <Table border :columns="columns" :data="data"></Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      columns: [
        {
          title: "分数",
          key: "fenshu",
          className: "table-head-style",
        },
        {
          title: "用时",
          key: "yongshi",
          className: "table-head-style",
        },
        {
          title: "提交时间",
          key: "dateTime",
          className: "table-head-style",
        },
      ],
      data: [
        {
          fenshu: "10",
          yongshi: "12'12",
          dateTime: "03-12 12:21 星期四",
        },
        {
          fenshu: "10",
          yongshi: "12'12",
          dateTime: "03-12 12:21 星期四",
        },
        {
          fenshu: "10",
          yongshi: "12'12",
          dateTime: "03-12 12:21 星期四",
        },
        {
          fenshu: "10",
          yongshi: "12'12",
          dateTime: "03-12 12:21 星期四",
        },
        {
          fenshu: "10",
          yongshi: "12'12",
          dateTime: "03-12 12:21 星期四",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.grade_m1 {
  margin: 0 0 0 22px;
}

.content {
  padding-bottom: 50px;
}

.wrapper {
  padding: 0 130px 0 27px;

  /deep/ textarea {
    resize: none;
  }
}

.title_text {
  margin: 16px 0 12px;
}

.wrapper /deep/ .ivu-btn {
  display: block;
  margin: 20px 0 0 0;
}

/deep/ .ivu-table th.table-head-style {
  padding: 12px 0;
  background-color: #ffffff;
}
</style>
