import router from "../../router/index.js";

export default {
  // 跳转页面
  get_link(a, b) {
    console.log(b);

    router.push({
      path: a,
      query: b,
    });
    window.scroll(0, 0);
  },
  // 获取当前年月日
  addDate() {
    const nowDate = new Date();
    const date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate(),
      hour: nowDate.getHours(), // 时
      minute: nowDate.getMinutes(), // 分
    };
    const newmonth = date.month > 10 ? date.month : "0" + date.month;
    const day = date.date > 10 ? date.date : "0" + date.date;
    const hour = date.hour > 10 ? date.hour : "0" + date.hour;
    const minute = date.minute > 10 ? date.minute : "0" + date.minute;
    return date.year + "-" + newmonth + "-" + day;
  },
  // 英文字母
  letter() {
    let list = [];
    const array = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
    ];

    array.forEach((n, k) => {
      for (let i = 0; i < 10; i++) {
        list.push(n + i);
      }
    });

    return list;
  },
};
