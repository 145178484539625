<template>
  <div id="app">
    <div v-if="is_head_show">
      <Head></Head>
    </div>
    <router-view />
  </div>
</template>

<script>
import Head from "./components/Head/index.vue";

export default {
  components: {
    Head,
  },
  data() {
    return {
      is_head_show: false,
    };
  },
  watch: {
    $route(to, from) {
      let routerName = this.$route.name;
      // 监听路由是否变化
      if (
        routerName != "Login" &&
        routerName != "Reg" &&
        routerName != "Password"
      ) {
        this.is_head_show = true;
      } else {
        this.is_head_show = false;
      }
    },
  },
  mounted() {
    let routerName = this.$route.name;
    console.log(this.$route.name);
    if (
      routerName != "Login" &&
      routerName != "Reg" &&
      routerName != "Password"
    ) {
      this.is_head_show = true;
    } else {
      this.is_head_show = false;
    }
  },
  created() {
    // this.$nextTick(() => {
    //     // 禁用右键
    //     document.oncontextmenu = new Function("event.returnValue=false");
    //     // 禁用选择
    //     document.onselectstart = new Function("event.returnValue=false");
    // });
  },
};
</script>

<style>
@import "./assets/css/pulic.css";

.location {
  margin-top: 20px;
}

.cur {
  cursor: pointer;
}

.noTip {
  width: 890px;
  text-align: center;
  padding: 200px 50px;
}

.red1 {
  color: #2d8cf0;
}

.bg1 {
  background-color: #2d8cf0;
  color: #ffffff;
  padding: 0 5px;
}

.hover:hover span {
  color: #2d8cf0;
}
</style>
