<template>
  <div class="content_title flex03">
    <div class="font_16">{{ name }}</div>
    <Button type="primary" size="large" class="btn_border" @click="back" ghost
      >返回上一级</Button
    >
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style lang="less" scoped></style>
