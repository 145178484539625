<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="学员主页"></Title>
      <div class="content" v-if="con">
        <div class="top_wrapper">
          <div class="top_block font_14">
            <p class="top_label">{{con.studentName}}:</p>
            <div class="top_block_content">
              <div class="top_block_center">积分：{{con.totalScore}}*{{con.studentWeight.weight}} + {{con.studentWeight.modifyScores}} = {{con.sum}}</div>
              <input placeholder="" class="top_block_input top_block_center" v-model="score" v-if="changeStatus" />
              <Button type="primary" size="large" class="btn_bg" style="background:#fff;color:#333;margin-right:10px" @click="getChangeStatus()" v-if="changeStatus">取消</Button>
              <Button type="primary" size="large" class="btn_bg" @click="getModifyScoreApi">
                <template v-if="!changeStatus">修改</template>
                <template v-else>提交</template>
              </Button>
            </div>
          </div>
          <div class="flex02" style="margin-top: 22px">
            <div class="top_block font_14">
              <p class="top_label">备注:</p>
              <div class="top_block_content">
                <textarea class="top_block_input text top_block_center" v-model="remarks"></textarea>
                <Button type="primary" size="large" class="btn_bg" style="margin-top: 48px" @click="submitMark">确认</Button>
              </div>
            </div>
            <div style="margin-left: 141px">
              <div class="top_block font_14">
                <p class="top_label">家长姓名：</p>
                <div class="top_block_content">
                  <input placeholder="" class="top_block_input top_block_center" v-model="parentName" />
                  <Button type="primary" size="large" class="btn_bg" @click="updateParentInfo">确认</Button>
                </div>
              </div>
              <div class="top_block font_14" style="margin-top: 12px">
                <p class="top_label">家长电话：</p>
                <div class="top_block_content">
                  <input placeholder="" class="top_block_input top_block_center" v-model="parentCall" maxlength="11" />
                  <Button type="primary" size="large" class="btn_bg" @click="copy">复制</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Tabs value="a0" @on-click="changeList">
            <TabPane label="得分记录" name="a0">
              <Table stripe :columns="columns0" :data="data0"></Table>
            </TabPane>
            <TabPane label="查看讲解记录" name="a1">
              <Table stripe :columns="columns1" :data="data1"></Table>
            </TabPane>
            <TabPane label="查看答案记录" name="a2">
              <Table stripe :columns="columns2" :data="data2"></Table>
            </TabPane>
            <TabPane label="签到记录" name="a3">
              <Table stripe :columns="columns3" :data="data3"></Table>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modifyScoreApi, showToast,studentDetailApi,studentSubmitNoteApi,studentScoreListApi,explainLogApi,answerLogApi,signLogApi,updateParentInfoApi} from "@/api/index.js";
export default {
  data() {
    return {
      studentId:null,
      score:0, //分数
      con:null,
      remarks:'', //备注
      changeStatus:false,
      


      columns0: [
        {title: "序号",key: "xuhao"},
        {title: "题目",key: "title"},
        {title: "分数",key: "score"},
        {title: "用时",key: "useTime"},
        {title: "提交时间",key: "createTime"},
      ],
      data0: [],
      
      columns1: [
        {title: "序号",key: "xuhao"},
        {title: "题目",key: "name"},
        {title: "查看时间",key: "last_login_time"}
      ],
      data1: [],

      columns2: [
        {title: "序号",key: "xuhao"},
        {title: "题目",key: "name"},
        {title: "查看时间",key: "last_login_time"}
      ],
      data2: [],

      columns3: [
        {title: "序号",key: "xuhao"},
        {title: "课程",key: "courseName"},
        {title: "签到时间",key: "createTime"}
      ],
      data3: [],

      parentCall:'', //家长电话，
      parentName:'', //家长姓名
    };
  },
  mounted() {
    
    this.studentId = this.$route.query.id;
    this.getStudebtDetail();
    this.getList()
  },
  methods: {
    copy(){
      let copyCon  = `家长姓名：${this.parentName}\n家长电话：${this.parentCall}`
      this.$copyText(copyCon).then( res => {
          this.$Message.success("复制成功");
      }, function (err) {
        this.$Message.error(err);
      })
    },
    // 提交家长信息
    updateParentInfo(){
      let data = {
        studentId:this.studentId,
        parentName:this.parentName,
        parentCall:this.parentCall
      }
      let tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if(data.parentName.length === 0) return showToast(this,'家长姓名不能为空');
      if(data.parentCall.length === 0) return showToast(this,'家长手机号不能为空');
      if(!tel.test(data.parentCall)) return  showToast(this,'手机号填写有误');
      
      updateParentInfoApi(data).then(res=>{
        showToast(this,'家长信息修改成功',1);
      })
    },
    // 获取记录列表  studentScoreListApi,explainLogApi,answerLogApi,signLogApi
    getList(type=0){
      let url = studentScoreListApi;
      if(type === 0){
        url = studentScoreListApi
      }else if(type === 1){
        url = explainLogApi
      }else if(type === 2){
        url = answerLogApi
      }else if(type === 3){
        url = signLogApi
      }
      url({studentId:this.studentId}).then(res=>{
        this['data'+type] = res.map((m,index) => {m.xuhao = index+1;return m});
      })
    },
    changeList(e){
      console.log(e);
      let listNum = 'listNum'+e;
      let type = parseInt(e.split('')[1]);
      console.log('type',type)
      this.getList(type)
    },
    // 获取学生详情
    getStudebtDetail(){
      studentDetailApi({studentId:this.studentId}).then(res=>{
        let totalScore = Number(res.totalScore) || 0;
        let weight = 0;
        let modifyScores = 0
        if(res.studentWeight){
          weight = Number(res.studentWeight.weight) || 0;
          modifyScores = Number(res.studentWeight.modifyScores) || 0;
        }
        res.totalScore = totalScore
        res.sum = totalScore*weight + modifyScores;
        res.studentWeight.weight = weight;
        res.studentWeight.modifyScores = modifyScores
        this.con = res;
        this.parentCall = res.parentCall;
        this.parentName = res.parentName;
        this.remarks = res.remarks;
        this.score = res.studentWeight.modifyScores
      })
    },
    // 修改分数
    getModifyScoreApi(){
      if(this.changeStatus){
        modifyScoreApi({studentId:this.studentId,score:this.score}).then(res=>{
          showToast(this,'修改成功',1);
          this.changeStatus = false;
          this.getStudebtDetail()
        })
      }else{
        this.changeStatus = true
      }
    },
    
    // 取消修改分数
    getChangeStatus(){
      this.changeStatus = false;
    },
  
    submitMark(){
      if(this.remarks.length === 0) return showToast(this,'备注不能为空')
      studentSubmitNoteApi({studentId:this.studentId,remarks:this.remarks}).then(res=>{
        showToast(this,'备注提交成功',1);
      })
    },
  },
};
</script>

<style lang="less" scoped>
.top_wrapper {
  font-size: 0;
  padding: 18px 27px 30px;
  border-bottom: 1px solid #ebeef5;
}

.top_block {
  display: flex;
  line-height: 36px;
}

.top_label {
  width: 74px;
}

.top_block_content {
  display: flex;
}

.top_block_input {
  height: 36px;
  padding-left: 10px;
  border-radius: 4px;
  border: 1px solid #f4f4f4;

  &.text {
    height: 84px;
    resize: none;
  }
}

.top_block_center {
  width: 300px;
  margin-right: 22px;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #355e7e;
}

.box /deep/ .ivu-tabs-ink-bar {
  background-color: #355e7e;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #355e7e;
}

.box /deep/ .ivu-tabs-nav-scroll {
  padding: 0 0 0 11px;
}

.box /deep/ .ivu-tabs-bar {
  margin-bottom: 15px;
  border-color: #f5f7fa;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab {
  padding-top: 16px;
  padding-bottom: 16px;
}
</style>
