<template>
	<div class="box font_14">
		<div class="w_1440 bg_fff w_m20">
			<div class="top_wrapper">
				<div class="top_left">
					<img src="../../assets/image/a@2x.png" />
					<p class="font_16">班级列表</p>
				</div>
				<div class="top_right">
					<router-link to="Copy"><Button type="primary" size="large" class="btn_border" ghost>抄写管理</Button></router-link>
					<Button type="primary" size="large" class="btn_border" ghost @click="sign()" style="margin-left: 10px;" v-if="loginType == 0">签到</Button>
				</div>
			</div>
			<Form :model="searchData" :label-width="78">
				<div class="select_wrapper flex01">
					<div class="select_left">
						<div class="select_left_block">
							<FormItem label="年份：">
								<!--     -->
								<Select v-model="searchData.year">
									<Option :value="item.text" v-for="(item, index) in yearList" :key="index">{{item.text}}</Option>
								</Select>
							</FormItem>
						</div>
						<div class="select_left_block">
							<FormItem label="季节：">
								<Select v-model="searchData.quarter">
									<Option :value="item.text" v-for="(item, index) in quarterList" :key="index">{{item.text}}</Option>
								</Select>
							</FormItem>
						</div>
						<div class="select_left_block">
							<FormItem label="教师：">
								<Select v-model="searchData.teacherId">
									<Option :value="item.id" v-for="(item, index) in tearchList" :key="index">{{item.teacherName}}</Option>
								</Select>
							</FormItem>
						</div>
						<div class="occupied">空空</div>
						<div class="select_left_block">
							<FormItem label="班级类型：">
								<Select v-model="searchData.classTypeId">
									<Option :value="item.id" v-for="(item, index) in classTypeList" :key="index">{{item.classTypeName}}</Option>
								</Select>
							</FormItem>
						</div>
						<div class="occupied">空空</div>
						<div>
							<Input placeholder="请输入学员姓名" v-model="searchData.studentName" style="width: 130px" />
						</div>
						<div class="occupied">空空</div>
						<div class="select_left_block">
							<FormItem label="课程类型：">
								<Select v-model="searchData.courseStatus">
									<Option :value="item.code" :key="item.text" v-for="(item, index) in courseList">{{item.text}}</Option>
								</Select>
							</FormItem>
						</div>
					</div>
					<div class="select_right">
						<Button type="primary" size="large" class="btn_bg" @click="getList()">查询</Button>
					</div>
				</div>
			</Form>
			<div class="content">
				<div class="list" v-for="(item, index) in classList" :key="index">
					<div class="list_top flex01">
						<div class="list_top_left flex02">
							<p class="title_line"></p>
							<div>班级类型：{{ item.classTypeName }}</div>
							<div>班级名：{{ item.courseName }}</div>
							<div>教师：{{ item.teacherName }}</div>
							<div>验证码：{{ item.captcha }}</div>
						</div>
						<div class="list_top_right">
							<router-link :to="'Detail?classId='+item.courseId"><Button type="primary" class="btn_border2 index_m1" ghost>班级详情</Button></router-link>
							<router-link :to="'cartogram?classId='+item.courseId"><Button type="primary" class="btn_border2" ghost>统计图</Button></router-link>
						</div>
					</div>
					<div class="list_content">
						<Table :height="tabH" :columns="item.columns" :data="item.data">
							<template slot-scope="{ row, index }" slot="action">
								<router-link :to="'Student?id='+row.studentId" v-if="loginType == 1 || loginType == 2">{{row.studentName}}({{row.level}})</router-link>
								<span v-if="loginType == 0" class="red1">{{row.studentName}}({{row.level}})</span>
							</template>
						</Table>
					</div>
				</div>
			</div>
		</div>
		<!-- 模态框 -->
		<Modal v-model="modal1=='true' && videoList.length > 0" width="800" title="视频" footer-hide @on-cancel="closeVideo">
			<div v-for="(item,index) in videoList"><video class="video_wrapper" :src="item.videoPath" controls="controls" v-if="videoIdx === index"></video></div>
			<div class="video-btns">
				<Button class="btn" v-if="videoIdx>0" @click="vPlay(0)">上一集</Button>
				<Button class="btn" v-if="videoIdx < videoList.length-1" @click="vPlay(1)">下一集</Button>
			</div>
		</Modal>
		<Modal v-model="modal_sign" title="签到" @on-ok="get_modal_ok">
			<div class="font_14">
				<div class="flex02 modal_list">
					<p class="modal_label">签到班级(课程)：</p>
					<div class="modal_wflex">
						<Select v-model="modal_info.courseId" style="width:200px" @on-change="get_changeCourse">
							<Option v-for="item in signList" :value="item.courseId" :key="item.courseId">{{ item.classTypeName }}</Option>
						</Select>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到课节：</p>
					<div class="modal_wflex">
						<Select v-model="modal_info.courseSchedulId" style="width:200px" @on-change="get_changeCourseSchedul">
							<Option v-for="item in courseSchedul_list" :value="item.id" :key="item.id">{{ item.orders + '(' + item.classStartTime + '-' + item.classEndTime + ')' }}</Option>
						</Select>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到学员：</p>
					<div class="modal_wflex">
						<p>{{modal_info.studentName}}</p>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到状态：</p>
					<div class="modal_wflex flex02">
						<img src="../../assets/image/yuan.png" class="modal_circle" />
						<div>签到</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		classStu,
		classStudent,
		classProblem,
		classStudentApi,
		getByTypeApi,
		getTeacherApi,
		getClassTypeApi,
		testApi,
		homeVideoListApi,
		courseSignInApi,
		showToast
	} from "@/api/index.js";

	import {
		signIn,
		courseScheduleList
	} from '@/api/mxy.js';

	export default {
		data() {
			return {
				signList:[],
				modal_sign: false,
				modal_info: {},
				courseSchedul_list: [], // 课节
				//
				modal1: true, //localStorage.getItem('isFirstLogin'),
				classList: [],
				year: '',
				searchData: {
					year: '',
					quarter: '',
					teacherId: '',
					classTypeId: '',
					studentName: '',
					courseStatus: '0'
				},
				yearList: [], //年份
				quarterList: [], //季节
				tearchList: [], //老师
				courseList: [], //课程类型
				classTypeList: [], //班级类型
				videoList: [],
				videoIdx: 0,
				videoSrc: '',
				videoBox: '',
				loginType: localStorage.getItem('loginType'),
				list: [],
				columns:[{
					width: 90,
					title: "序号",
					key: "number",
					fixed: "left",
					className: "table-head-style",
				},
				{
					width: 150,
					title: "姓名",
					key: "studentName",
					slot: 'action',
					fixed: "left",
					className: "table-head-style",
				},
				{
					width: 160,
					title: "总得分",
					key: "total",
					className: "table-head-style",
				}],
        tabH:''
			};
		},
		mounted() {
      this.tabH = document.body.clientHeight - 360
			// 班级列表
			classStudentApi({
				classTypeId: "",
				courseStatus: "",
				quarter: "",
				studentName: "",
				teacherId: "",
				year: ""
			}).then( res => {
				console.log('11111',res.length)
				this.signList = res;
				
				if(res.length > 0){
					this.modal_info.courseId = res[0].courseId;
					this.get_changeCourse()
				}
				this.getList()
			})
			

			if (this.modal1 === true) {
				homeVideoListApi({}).then(res => {
					if (res.length > 0) {
						this.videoSrc = res[0].videoPath
					}
					this.videoList = res;

				})
			}

			this.modal_info.studentName = JSON.parse(localStorage.getItem('user')).name
		},
		methods: {
			//签到
			sign() {
				this.modal_sign = true;
			},
			get_changeCourse() {
				courseScheduleList({
					courseId: this.modal_info.courseId,
					status: 1
				}).then(res => {
					this.courseSchedul_list = res;
					if(res.length > 0){
						this.modal_info.courseSchedulId = res[0].id
					}
				})
			},
			get_changeCourseSchedul() {
				
			},
			get_modal_ok() {
				if (!this.modal_info.courseId) return this.$Message.error('请选择签到班级');
				if (!this.modal_info.courseSchedulId) return this.$Message.error('请选择签到课节');

				signIn({
					courseId: this.modal_info.courseId,
					courseSchedulId: this.modal_info.courseSchedulId
				}).then(res => {
					this.$Message.success('签到成功');
				})
			},
			closeVideo() {
				localStorage.setItem('isFirstLogin', 'false')
			},
			vPlay(type) {
				if (type === 0) {
					this.videoIdx = this.videoIdx - 1

				} else {
					this.videoIdx = this.videoIdx + 1
				}
				this.videoSrc = this.videoList[this.videoIdx].videoPath;
				
			},
			getList() {
				let data = this.searchData
				classStudentApi(data).then(res => {
          console.log('走起')
					this.getCon();
					// res[0].topList[0].list[0].problemGroupName = '我是自定义的';
					// res[1].topList[0].list[0].problemGroupName = '我是自定义的2';
					// res[0].topList[0].list[0].level = 1;
					// res[0].topList[0].student.total = 4
					if (res) {
						res.forEach((n, k) => {
							if (n.topList.length > 0) {
								n.columns = this.columns;
								let list = [];
								n.topList.forEach((topItem, topIdx) => {
									//当前条
									let curListItem = {
										studentName: topItem.student.studentName,
										total: topItem.student.total,
										number: topIdx+1,
										studentId: topItem.student.studentId,
										level: topItem.student.level,
									}
									topItem.list.forEach((listItem, listIdx) => {
										if(topIdx === 0){
											let s = {
												title: listItem.problemGroupName + '(' + (listItem.courseNum || 0) + ')',
												key: 'score' + listIdx,
												className: "table-head-style",
                        width: 180,
											}
											n.columns = n.columns.concat(s)
										}
										curListItem['score' + listIdx] = listItem.isFinish == 1 ? listItem.score : '未完成';
										
									})
									list = list.concat(curListItem)
								})
								n.data = list;
							}

						})
						
						this.classList = res;
					}
				})

			},

			getType(type) {
				// 1-年，2-季节，3-课程状态
				let data;
				let list
				if (type === 1) {
					data = 'YEAR';
					list = 'yearList'
				} else if (type === 2) {
					data = 'QUARTER';
					list = 'quarterList'
				} else if (type === 3) {
					data = 'COURSE_TYPE';
					list = 'courseList'
				}
				getByTypeApi({
					type: data
				}).then(res => {
					if (res) {
						this[list] = res
					}
				})
			},
			getYear(e) {
				this.searchData.year = e.substr(0, 4)
			},
			getCon() {
        this.getType(1);
        this.getType(2)
        this.getType(3)
				getTeacherApi({}).then(res => {
					if (res) {
						this.tearchList = res;
					}
				})
				getClassTypeApi({}).then(res => {
					if (res) {
						this.classTypeList = res
					}
				})
				
			}
		},
	};
</script>

<style lang="less" scoped>
	/deep/ #DatePicker .ivu-date-picker-cells-year span em {
		font-size: 12px !important
	}

	.index_m1 {
		margin-right: 20px;
	}

	.video-btns .btn {
		cursor: pointer;
		width: 80px;
		line-height: 30px;
		border: 1px solid #dddddd;
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		border-radius: 3px;
	}

	.video-btns .btn:hover,
	.video-btns .btn:active {
		border-color: #2d8cf0;
		color: #2d8cf0;
	}

	.top_wrapper {
		display: flex;
		height: 59px;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ebeef5;
	}

	.top_left {
		display: flex;
		align-items: center;

		img {
			width: 19px;
			height: 19px;
			margin: 0 10px 0 24px;
		}
	}

	.top_right {
		padding-right: 130px;
	}

	.select_wrapper {
		height: 70px;
		padding-right: 130px;
		border-bottom: 1px solid #ebeef5;
	}

	.select_left {
		flex: 1;
		display: flex;
	}

	.select_left_block {
		flex: 1;
	}

	.select_right {
		margin-left: 20px;
	}

	.occupied {
		color: #fff;
	}

	.content {
		padding: 0 14px;
		overflow: hidden;
	}

	.list {
		margin: 18px 0;
		border: 1px solid #f5f7fa;
	}

	.list_top {
		height: 48px;
		padding-right: 115px;
		border-bottom: 1px solid #f5f7fa;
		background-color: rgba(245, 247, 250, 0.4);
	}

	.list_top_left {
		flex: 1;
	}

	.list_top_left div {
		margin-right: 26px;
	}

	.title_line {
		width: 3px;
		height: 13px;
		border-radius: 4px;
		margin: 0 7px 0 34px;
		background-color: #355e7e;
	}

	.list_content {
		padding: 0 17px;
		margin-bottom: 16px;
	}

	.video_wrapper {
		width: 100%;
	}

	.box /deep/ .ivu-form .ivu-form-item-label {
		padding: 11px 8px 11px 0;
	}

	.box /deep/ .ivu-form-item {
		margin-bottom: 0;
	}

	.box /deep/ .ivu-form-item-content {
		line-height: 36px;
		font-size: 0;
	}

	.box /deep/ .ivu-select-single .ivu-select-selection {
		height: 36px;
	}

	.box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
	.box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
		height: 34px;
		line-height: 34px;
	}

	.box /deep/ .ivu-input {
		height: 36px;
	}

	/deep/ .ivu-table th.table-head-style {
		padding: 12px 0;
		background-color: #ffffff;
	}

	/deep/ .ivu-table th.lan {
		color: #355e7e;
		cursor: pointer;
	}

	.modal_list {
		margin: 20px 0;
	}

	.modal_label {
		width: 160px;
	}

	.modal_wflex {
		flex: 1;

		.modal_circle {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}
</style>
