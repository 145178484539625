<template>
  <div class="box">
    <div class="w_1440">
      <div class="bg_fff w_m20">
        <div class="top_btn">
          <Title name="消息中心"></Title>
          <router-link to="/Send" class="sendBtn" v-if="loginType != 0"><Button class="ivu-btn-large">发送消息</Button></router-link>
      </div>
        <div class="content">
          <Collapse simple v-model="value1">
            <Panel :name="index + 1 + ''" v-for="(item, index) in list" :key="index">
              <div class="title" @click="messageRead(item.id,index)">
                <img src="../../assets/image/b@2x.png" v-if="item.status == '1'"/>
                <img src="../../assets/image/c@2x.png" v-else />
                <p class="font_14">{{ item.title }}</p>
                <p class="font_14 title_time" v-if="item.createTime">
                  {{ item.createTime.substr(0, 10) }}
                </p>
              </div>
              <div slot="content" class="center_wrapper">
                <div class="font_14 color_666 content_text">
                  <span style="float: left">内容：</span>
                  <div v-html="item.content"></div>
                </div>
                <div class="down_wrapper font_14" v-if="item.appendix.length > 0">
                  <p>附件：</p>
                  <div class="down_content">
                    <template v-for="(linkItem, linkIdx) in item.appendix">
                      <div class="down_block" v-if="linkItem.path">
                        <img src="../../assets/image/d@2x.png" />
                        <p>{{ linkItem.name }}</p>
                        <a :href="linkItem.link" download="">下载</a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <Page :total="total" show-total v-if="total > 0" style="margin-top: 30px" @on-change="getList($event)"/>
    </div>

    <div class="noTip" v-if="list.length === 0" style="padding:60px; text-align: center">暂无数据</div>
  </div>
</template>

<script>
import { showToast, leaveMessageApi,messageReadApi,messageRemindApi } from "@/api/index.js";
export default {
  data() {
    return {
      value1: "",
      list: [],
      total: 0,
      loginType:localStorage.getItem('loginType')
    };
  },
  mounted() {
    let loginType = localStorage.getItem('loginType');
    this.getList();
    
  },
  computed:{
    // infoNums() {
    //   const infoNums = this.$store.state.infoNums;
    //   console.log('infoNums===>',localStorage.getItem('infoNums'))
    //   return infoNums ? infoNums : 0;
    // },
    infoNums: {
      get() {
        return this.$store.state.infoNums;
      },
      set(val) {
        console.log('val=====>',val)
        this.$store.state.infoNums = val;
      },
    },
  },
  methods: {
    messageRead(msgId,index){
      this.list[index].status = 1
      messageReadApi({msgId:msgId}).then(res=>{
        messageRemindApi({}).then(res=>{
          this.$store.state.infoNums = res.filter(n => n.status == 0).length;
        })
      })
    },
    
    getList(e = this.pageNum) {
      this.pageNum = e;
      let loginType = localStorage.getItem('loginType');  //0-学生，1-老师
      let type = 1;
      if(loginType == 0){
        type = 3
      }else if(loginType == 1){
        type = 2
      }
      leaveMessageApi({ type:type}).then((res) => {
        if(res){
          this.total = parseInt(res.total);
          this.list = res.map((m) => {
            if(JSON.parse(m.appendix)){
              m.appendix = JSON.parse(m.appendix);
            }else{
              m.appendix = {path:'',name:''};
            }
            return m;
          });
        }
        
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top_btn{position: relative;}
.sendBtn{position:absolute;right: 260px;top: 50%; transform: translateY(-50%);display: block}
.sendBtn .ivu-btn-large{background-color: #f5f5f5 !important;}
.content {
  padding: 0 260px 0 27px;
}

.title {
  color: #333;
  display: flex;
  overflow: hidden;
  padding: 15px 30px 15px 0;

  img {
    height: 14px;
    margin: 3px 8px 0 0;
  }

  p {
    &:nth-child(2) {
      flex: 1;
    }
  }

  .title_time {
    color: #999;
    margin-left: 50px;
  }
}

.content_text {
  margin: 0 0 16px;
}

.center_wrapper {
  padding: 12px 0 0;
}

.down_wrapper {
  display: flex;
}

.down_content {
  flex: 1;
  display: flex;
}

.down_block {
  text-align: center;
  margin-right: 28px;

  img {
    height: 39px;
    margin: 0 auto;
  }

  a {
    display: block;
    color: #333;
    margin: 12px 0 0;
  }
}

.content /deep/ .ivu-collapse {
  border: none;
}

.content /deep/ .ivu-collapse > .ivu-collapse-item {
  border-color: #ebeef5;
}

.content /deep/ .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  padding-left: 0;
  min-height: 50px;
}

.content /deep/ .ivu-icon {
  top: 50%;
  right: 0;
  position: absolute;
  margin-right: 0 !important;
  -webkit-transform: translateY(-50%);
}

.content /deep/ .ivu-collapse-content {
  padding: 0 31px;
}
</style>
