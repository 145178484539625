// 导入axios
import Qs from "qs";
import axios from "axios";
import $publicFun from "../assets/js/public.js";

// iview组件
import { Message } from "view-design";
let baseurl
if (process.env.NODE_ENV === 'development') {
	// 本地
	baseurl = 'http://118.24.31.25:8123/';
} else {
		// 线上
		baseurl = '/';
}
// 进行一些全局配置
// 公共路由(网络请求地址)
if (process.env.NODE_ENV === "development") {
  // 本地
  axios.defaults.baseURL = baseurl;
} else {
  // 线上
  axios.defaults.baseURL = "/";
}

// 请求响应超时时间
axios.defaults.timeout = 5000;

// 转换fromdata请求数据
// 利用axios拦截器来进行转换，只需要转换一次
axios.interceptors.request.use(
  function (config) {
    // 请求头转换为表单形式
    let tokenName = localStorage.getItem("tokenName")
    config.headers[tokenName] = localStorage.getItem("tokenValue") || "";
    config.transformRequest = [
      function (data) {
        // 在请求之前对data传参进行格式转换
        data = Qs.stringify(data);
        return data;
      },
    ];
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 封装自己的get/post方法
export default {
  get: function (path = "", data = {}) {
    return new Promise(function (resolve, reject) {
      axios.get(path, {params: data,}).then(function (response) {
        // 登录失效
        if (response.data.errorCode == 401) {
          Message.error({content:'未登录'});

          setTimeout(() => {
            $publicFun.get_link("/Login", {});
          }, 1000);

          return;
        }
        // 接口没通
        if (!response.data.success) {
          Message.error({content:response.data.errorMsg || response.data.message});
          return;
        }

        resolve(response.data.result || {});
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  post: function (path = "", data = {}) {
    return new Promise(function (resolve, reject) {
      axios.post(path, data).then(function (response) {
        // 登录失效
        if (response.data.errorCode == 401) {
          Message.error({content:'未登录'});
          setTimeout(() => {
            $publicFun.get_link("/Login", {});
          }, 1000);
          return Promise.reject(response.data.errorMsg || response.data.message);
        }

        // 接口没通
        if (!response.data.success) {
          
          Message.error({content:response.data.errorMsg || response.data.message});
          return Promise.reject(response.data.errorMsg || response.data.message);
        }
        // else{
        //   if(response.data.result){
        //     resolve(response.data.result);
        //   }else{
        //     resolve(response.data.success)
        //   }
        // }
        resolve(response.data.result);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
};
