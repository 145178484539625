// 导入封装好的网络请求类工具
import NetworkHaidong from "./network_haidong.js";
import req from '../services/http.js';
// 封装各种接口请求
// export const 接口名 = () => NetworkHaidong.get('/路由',参数对象);
// export const problemGroup = (data) => NetworkHaidong.post('Student/problemGroup', data);
// 课表-班级课程组（表头）(和classProblem重复);
export const login = (data) => NetworkHaidong.post("user/login", data); // 登录
export const register = (data) => NetworkHaidong.post("user/register", data); // 学生注册

export const classStu = (data) => NetworkHaidong.post("Student/classStu", data); // 学生老师班级
export const classProblem = (data) => NetworkHaidong.post("Student/classProblem", data); // 查询班级习题组
export const classStudent = (data) => NetworkHaidong.post("/Student/classStudent", data); // 课表
export const classInfo = (data) => NetworkHaidong.post("Student/classInfo", data); // 班级详情数据
export const queryClassJob = (data) =>  NetworkHaidong.post("Student/queryClassJob", data); // 查询布置作业习题组
export const queryClassJobs = (data) =>  NetworkHaidong.post("Student/queryClassJobs", data); // 班级详情-习题组下的习题

export const queryProblemSetsByGroupId = (data) =>  NetworkHaidong.post("Student/queryProblemSetsByGroupId", data); // 习题组下的习题名称（表头）

export const errorSetsCommint = (data) =>  NetworkHaidong.post("Student/errorSetsCommint", data); // 改错题提交
export const errorSetsUpdate = (data) =>  NetworkHaidong.post("Student/errorSetsUpdate", data); // 改错题标准答案编辑
export const problemSetsCommint = (data) =>  NetworkHaidong.post("Student/problemSetsCommint", data); // 习题提交
export const isLinkApi = (data) => NetworkHaidong.post("Student/isLink", data); // 讲解是否听懂
export const msgPageApi = (data) =>  NetworkHaidong.post("Student/msgPage", data); // 消息分页
export const notesCommintApi = (data) =>  NetworkHaidong.post("Student/notesCommint", data); // 习题笔记提交
export const courseSignApi = (data) =>  NetworkHaidong.post("Student/courseSign", data); // 学生签到
export const signPageApi = (data) =>  NetworkHaidong.post("Student/signPage", data); // 签到分页
export const copyCommintApi = (data) =>  NetworkHaidong.post("Student/copyCommint", data); // 抄写提交






// **********************  刘东东接口  ********************** //
export const getByTypeApi = (data) => NetworkHaidong.post("dict/getByType", data); // 刘东东--字典获取
export const getClassTypeApi = (data) => NetworkHaidong.get("class-type/query", data); // 刘东东--获取班级类型


export const getListByClassIdApi = (data) => NetworkHaidong.post("base/student-score/listByClassId", data); // 刘东东--班级统计图接口
export const modifyScoreApi = (data) => NetworkHaidong.post("base/student-score/modifyScore", data); // 刘东东--班级统计图修改分数
 // 刘东东--抄写---COPY_NUMBER 全积分计算---INTEGRAL_CALCULATION   使用说明----INSTRUCTIONS  升级天数---UPGRADE_NUMBER_DAYS
 export const getConfByKeyApi = (data) => NetworkHaidong.get("base/sys-conf/getConfByKey", data);
 export const studentDetailApi = (data) => NetworkHaidong.get("student/detail", data); //刘东东--学生主页，学生详情
 export const studentSubmitNoteApi = (data) => NetworkHaidong.post("student/submitNote", data); //刘东东--提交备注信息
 export const studentScoreListApi = (data) => NetworkHaidong.get("student/studentScoreList", data); //刘东东--学生主页得分记录
 export const viewLogApi = (data) => NetworkHaidong.get("student/viewLog", data); //刘东东--查看讲解查看答案时调用
 export const explainLogApi = (data) => NetworkHaidong.get("student/explainLog", data); //刘东东--讲解记录
 export const answerLogApi = (data) => NetworkHaidong.get("student/answerLog", data); //刘东东--答案记录
 export const signLogApi = (data) => NetworkHaidong.get("student/signLog", data); //刘东东--签到记录
 export const clockInApi = (data) => NetworkHaidong.get("base/clock-in-log/clockIn", data); //刘东东--打卡
 export const copyListApi = (data) => NetworkHaidong.get("student/copyList", data); //刘东东--抄写管理
 export const updateParentInfoApi = (data) => NetworkHaidong.post("student/updateParentInfo", data); //刘东东--修改家长信息
 


 export const getTeacherApi = params => req('post', 'teacher/query', params) //刘东东--查询所有老师
 export const newProblemSetsApi = params => req('post', 'problem-sets/newProblemSets', params) //刘东东--新增习题
 export const problemDetailApi = params => req('get', 'problem-sets/detail', params) //刘东东--习题详情
 export const queryChooseProblemApi = params => req('get', 'problem-sets/queryChooseProblem', params) //刘东东--根据习题ID查询选择填空题列表
 export const chooseProblemDetailApi = params => req('get', 'problem-sets/chooseProblemDetail', params) //刘东东--选择填空题详情
 export const deleteChooseProblemApi = params => req('get', 'problem-sets/deleteChooseProblem', params) //刘东东--删除填空选择题
 export const chooseUpdateApi = params => req('post', 'problem-sets/newChooseProblem', params) //刘东东--删除填空选择题
 
 export const signQueryApi = params => req('get', 'course/course-sign/query', params) //刘东东--签到统计
 export const courseQueryApi = params => req('get', 'course/query', params) //刘东东--课程查询
 export const leaveMessageApi = params => req('get', 'leave-message/query', params) //刘东东--消息列表
 export const leaveMessageSubmitApi = params => req('post', 'leave-message/submit', params) //刘东东--发送消息
 export const studentQueryApi = params => req('post', 'student/query', params) //刘东东--所有学生
 export const studentNotesSubmitApi = params => req('post', 'base/student-notes/submit', params) //刘东东--修改学生笔记查询
 export const getIntegralApi = params => req('get', 'student/getIntegral', params) //刘东东--根据学生ID获取学生积分
 export const homeVideoListApi = params => req('get', 'base/home-video/query', params) //刘东东--首页视频列表
 export const courseSignInApi = params => req('post', 'course/course-sign/signIn', params) //签到
 export const teacherCallRollApi = params => req('post', 'course/course-sign/teacherCallRoll', params) //老师点名签到接口
 export const cancelSignInApi = params => req('post', 'course/course-sign/cancelSignIn', params) //老师取消点名
 export const messageRemindApi = params => req('get', 'leave-message/remind', params) //消息数量提醒
 export const messageReadApi = params => req('get', 'leave-message/read', params) //消息数量提醒

 
//  提示框
 export function showToast(that, tip, type = 0) {
   let typeTxt = type === 0 ? 'error' : 'success';
   that.$Message[typeTxt](tip);
}
// 图片上传接口
export function uploadApi1() { return 'http://118.24.31.25:8123/upload' }
// 图片上传接口
export function uploadApi() { return 'http://118.24.31.25:8123/richTextUpload' }

import Wangeditor from "wangeditor";
// 富文本编辑
export function editorCreate(that,name){
  var editor = new Wangeditor(that.$refs[name])
  editor.config.showLinkImg = false
  editor.config.uploadImgServer = uploadApi(),//'http://localhost:3000/upload' // 上传图片的接口地址
  editor.config.uploadFileName = 'file' // formdata中的name属性
  editor.config.debug = true // 开启debug模式
  editor.config.uploadImgHeaders = {
    Authorization: localStorage.getItem('toutoken') // 设置请求头
  }
  editor.config.uploadImgHooks = {
    // 图片上传并返回结果，但图片插入错误时触发
    fail: function (xhr, editor, result) {
      console.log('fail===>',result)
    },success: function (xhr, editor, result) {
      // 图片上传并返回结果，图片插入成功之后触发
      console.log( 'success',result)
    }
    
  }
  editor.create()
}

// 获取文本
export function getTxt(idName){
  let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0]
  return text ? text.innerText : ''
}
// 获取Html
export function getHtml(idName){
  let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0]
  return text ? text.innerHTML : ''
}

export const classStudentApi = params => req('post', 'schedule-page/classList', params) // 新--课表
export const classInfoApi = params => req('get', 'base/class-info/detail', params) //刘东东--班级详情接口
export const thisWorkApi = params => req('get', 'student/thisWork', params) //刘东东--本次作业接口
export const historyWorkApi = params => req('get', 'student/historyWork', params) //刘东东--历史作业
export const courseScheduleListApi = params => req('get', 'course/courseScheduleList', params) //刘东东--根据课程ID获取课节
export const queryCourseScheduleListApi = params => req('get', 'course/course-sign/queryCourseScheduleList', params) //刘东东--根据课程ID获取可能存在到课节列表
export const problemGroupApi = params => req('post', 'problem-sets-group/query', params) //刘东东--习题组列表
export const classJobSaveApi = params => req('post', 'Student/classJobSave', params) //刘东东--布置作业
export const submitWorkApi = params => req('post', 'course/student-problem-records/submitWork', params) //刘东东--提交作业
export const getCourseErrorProblemApi = params => req('get', 'course/getCourseErrorProblem', params) //刘东东--习题详情获取改错题
export const errorSubmitWorkApi = params => req('post', '/base/error-problem-work/submitWork', params) //刘东东--提交改错题作业
export const errorLookAnswerApi = params => req('get', 'base/error-problem-work/lookAnswer', params) //刘东东--查看改错题标准答案
export const updateCorrectContentApi = params => req('post', 'base/error-problem-work/updateCorrectContent', params) //刘东东--编辑改错题标准答案
export const giveUpApi = params => req('get', 'base/error-problem-work/giveUp', params) //刘东东--习题详情放弃改错题
export const chooseProblemWorkSubmitApi = params => req('post', 'base/choose-problem-work/submit', params) //刘东东--做选择填空题
export const studentNotesQueryApi = params => req('get', 'base/student-notes/query', params) //刘东东--学生笔记查询
// export const submitWorkApi = (data) =>  NetworkHaidong.post("course/student-problem-records/submitWork", data); // 刘东东--提交作业
export const copyQueryApi = (data) =>  NetworkHaidong.post("Student/copyQuery", data); // 抄写查询
export const linkInfoApi = (data) =>  NetworkHaidong.post("Student/linkInfo", data); // 讲解统计
export const linkPageApi = (data) =>  NetworkHaidong.post("Student/linkPage", data); // 讲解查询




export const queryProblemSetsByIdApi = (data) =>  NetworkHaidong.get("Student/queryProblemSetsById", data); // 刘东东--习题详情
export const setRestWorkApi = (data) =>  NetworkHaidong.get("course/restWork", data); // 作业页面-设置重写
export const setCopyNumberApi = (data) =>  NetworkHaidong.get("course/copyNumber", data); // 刘东东--作业页面-设置抄写
export const getNotesApi = (data) =>  NetworkHaidong.get("base/student-notes/getNotes", data); // 刘东东--获取学生笔记接口
export const getScoreByApi = (data) =>  NetworkHaidong.get("course/student-problem-records/getScoreBy", data); // 刘东东--获取学生历史成绩查询
export const rankByProblemIdApi = (data) =>  NetworkHaidong.get("base/student-copy/rankByProblemId", data); // 刘东东--根据习题ID获取抄写排行榜
export const lookExplainApi = (data) =>  NetworkHaidong.get("problem-sets/lookExplain", data); // 刘东东--查看讲解
export const lookAnswerApi = (data) =>  NetworkHaidong.get("problem-sets/lookAnswer", data); // 刘东东--根查看讲解
export const getProblemCopyNumbersApi = (data) =>  NetworkHaidong.get("problem-sets/getProblemCopyNumbers", data); // 刘东东--获取当前习题 的 抄写和要求抄写次数
export const workHistoryApi = (data) =>  NetworkHaidong.get("course/workHistory", data); // 获取学生历史提交作业记录



export const passwordVerifyApi = (data) =>  NetworkHaidong.post("user/passwordVerify", data); // 校验密码是否正确
export const updatePwdApi = (data) =>  NetworkHaidong.post("user/updatePwd", data); // 修改密码接口
export const deleteWorkApi = (data) =>  NetworkHaidong.post("course/deleteWork", data); // 取消本次作业接口


