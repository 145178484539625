<template>
  <!-- 抄写 -->
  <Modal footer-hide v-model="modal" title="抄写" @on-cancel="change" @on-visible-change="change" :mask-closable="false">
    <div>
      <div class="chaoxie_content flex02">
        <p>抄写：</p>
        <div style="flex: 1">
          <Input v-model="copyNumber" placeholder="" style="width: 100%" />
        </div>
      </div>
      <div class="flex04 btn_wrapper">
        <Button type="primary" size="large" class="btn_border" ghost @click="change(false)">取消</Button>
        <Button type="primary" size="large" class="btn_border" ghost style="margin-left: 18px" @click="get_ok(0,0)">取消抄写</Button>
        <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px" @click="get_ok(copyNumber,1)" >设置抄写</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import {setCopyNumberApi,showToast} from "@/api/index.js";
export default {
  props: {
    modal1: {type:Boolean, default:false},
    problemId: '',
    studentId: '',
    courseId:''
  },
  data() {
    return {
      copyNumber:0,
      modal:true
    };
  },
  methods: {
    get_ok(copyNumber,type) {
    	if(type === 1 && !copyNumber) return showToast(this,'抄写次数不能为空')
    	if(type === 1 && copyNumber == 0) return showToast(this,'抄写次数不能为0')
    	let data = {
    		problemId:this.problemId,
    		copyNumber:copyNumber,
    		studentId:this.studentId,
    		courseId:this.courseId
    	}
    	setCopyNumberApi(data).then(res=>{
    		showToast(this,'抄写设置成功',1)
    		this.copyNumber = null;
        this.$emit('setcopy',false);
    	})
    	
    },
    // 关闭重写
    change(e){
      if(!e){
        this.$emit('setcopy',false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .chongxie_content {
    color: #999;
    font-size: 12px;
    line-height: 16px;
  
    img {
      height: 19px;
      margin-right: 6px;
    }
  }
  .btn_wrapper {
    margin: 38px 0 0 0;
  }
</style>
