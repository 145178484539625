<template>
  <div class="box flex01 bg_fff">
    <div class="content bg_fff">
      <img src="../../assets/image/a.jpg" class="logo_img" />
      <p class="font_16 bold_ flex01">零一教育习题平台</p>
      <div class="wrapper">
        <div class="flex02 list">
          <Icon type="md-person" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
          <Input :border="false" v-model="form.username" placeholder="请输入账号" clearable />
        </div>
        <div class="flex02 list">
          <Icon type="md-lock" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
          <Input type="password" :border="false" v-model="form.password" placeholder="请输入旧密码" clearable />
        </div>
        <div class="flex02 list">
          <Icon type="md-lock" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
          <Input type="password" :border="false" v-model="form.newPassword" placeholder="请输入新密码" clearable />
        </div>
        <div class="flex02 list">
          <Icon type="md-lock" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
          <Input type="password" :border="false" v-model="form.again" placeholder="请再次输入新密码" clearable />
        </div>
        <Button type="primary" size="large" @click="submit()" class="btn_bg login_btn">立即修改</Button>
        <div class="flex04">
          <router-link to="/Login" class="font_14 color_355E7E" style="cursor: pointer">去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {passwordVerifyApi,updatePwdApi,login} from "@/api/index.js";
  export default {
    data() {
      return {
        form: {
          username: "",
          password: "",
          newPassword:'',
          again: "",
          code: "",
        },
      };
    },
    methods:{
      submit(){
        console.log('100000')
        passwordVerifyApi({ password:this.form.password }).then(res=>{
          console.log('res',res);
          if(res){
            if(!this.form.newPassword) return this.$Message.error('请输入新密码~');
            if(!this.form.again) return this.$Message.error('请再次输入新密码~');
            if(this.form.again != this.form.newPassword) return this.$Message.error('两次密码不一样，请仔细检查~');
            updatePwdApi({newPassword:this.form.newPassword}).then(res=>{
              let loginType = localStorage.getItem('loginType')
              login({
                userName: this.form.username,
                password: this.form.newPassword,
                loginType: loginType,
              }).then((res) => {
                localStorage.setItem("userId", res.detail.id);
                localStorage.setItem("tokenName", res.tokenInfo.tokenName);
                localStorage.setItem("tokenValue", res.tokenInfo.tokenValue);
                let name = "";
                if (loginType === 0) {
                  name = res.detail.studentName;
                } else if (loginType === 1) {
                  name = res.detail.teacherName;
                } else if (loginType === 2) {
                  name = res.detail.mangerName;
                }
                res.detail.name = name;
                localStorage.setItem("user", JSON.stringify(res.detail));
                // this.$router.push({
                //   path: "/",
                // });
                this.$router.go(-1);
                localStorage.setItem("isFirstLogin", res.isFirstLogin.toString());
              });
            })
          }else{
            this.$Message.error('密码不正确，请仔细检查~');
          }
          this.password = ''
        })
      }
    }
  };
</script>

<style lang="less" scoped>
  .box {
    padding-top: 0;
  }

  .content {
    width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: #ccc 0 0 10px;
  }

  .wrapper {
    margin: 30px 0 50px;
    padding: 0 50px;
  }

  .logo_img {
    width: 90px;
    margin: 40px auto 0;
  }

  .list {
    padding: 2px 0;
    overflow: hidden;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
  }

  .login_btn {
    margin: 10px 0 15px;
    cursor: pointer;
    height: 40px;
    width: 100%;
  }
</style>
