<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="编辑选择填空题"></Title>
      <div class="content hidden">
        <div v-for="(listItem, listIdx) in list" :key="listIdx">
            <p class="font_16 bold_ edit_title">{{listIdx == 0 ? '选择':'填空'}}题</p>
          <!-- 选择题 -->
          <div class="choose_wrapper hidden" v-for="(conItem, conIdx) in listItem" :key="conIdx">
            <div class="choose_title font_14 flex05 edit_m1">
              <div class="label">题目：</div>
              <div class="flexW1 editCon" v-html="conItem.title"></div>
              <div class="flex05 choose_title_btn">
                <p @click="chooseAdd(1,conItem)">编辑</p>
                <p @click="del(conItem,listIdx)">删除</p>
              </div>
            </div>
            <template v-for="(item, index) in conItem.items">
              <div>
                <div class="font_14 flex05 edit_m1">
                  <p class="label" v-if="conItem.type==0">
                    <span v-if="index===0">A：</span>
                    <span v-if="index===1">B：</span>
                    <span v-if="index===2">C：</span>
                    <span v-if="index===3">D：</span>
                  </p>
                  <p class="label" v-if="conItem.type==1">答案{{index+1}}：</p>
                  <div class="flexW1">{{item.item}}</div>
                </div>
              </div>
            </template>
            <div class="font_14 flex05 edit_m1" v-if="conItem.type == 0">
              <p class="label">答案：</p>
              <div class="flexW1">选项
                <span v-if="conItem.isTrueIdx == 0">A</span>
                <span v-if="conItem.isTrueIdx == 1">B</span>
                <span v-if="conItem.isTrueIdx == 2">C</span>
                <span v-if="conItem.isTrueIdx == 3">D</span>
              </div>
            </div>
            <div class="font_14 flex05 edit_m1">
              <p class="label">解析：</p>
              <div class="flexW1" v-html="conItem.parseContent"></div>
            </div>
          </div>
          <div class="edit_btn">
            <Button type="primary" size="large" class="btn_border" ghost @click="chooseAdd(0,{type:listIdx})" >添加{{listIdx == 0 ? '选择':'填空'}}题</Button>
          </div>
        </div>
        
      </div>
    </div>
    <!-- <div class="w_1440" v-if="content">
      <div class="edit_btn_wrapper flex04">
        <Button type="primary" size="large" class="btn_border" ghost>取消</Button>
        <Button type="primary" size="large" class="btn_bg" style="margin-left: 17px">提交</Button>
      </div>
    </div> -->
    <!-- 添加选择题 -->
    <Modal footer-hide v-model="modal_show1" width="928" :title="title">
      <div class="edit_m3">
        <div class="hidden">
          <div class="flex05 modal_m1">
            <p class="font_14 modal_label">标题：</p>
            <div class="flexW1">
              <div id="chooseBox1" ref="chooseBox1">
                <div v-html="content.title" v-if="chooseType == 1"></div>
                <div v-html="addChooseCon.title" v-else></div>
              </div>
            </div>
          </div>
          <div class="flex02 modal_m1" v-for="(item, index) in answerList" :key="index">
            <p class="font_14 modal_label"> <template v-if="type==0">选项</template><template v-if="type==1">答案</template>{{index+1}}：</p>
            <div>
              <Input v-model="item.item" placeholder="" style="width: 444px" />
            </div>
            <i class="modal_icon" @click="answerDel(index)" v-if="index > 0"></i>
          </div>
          <div class="flex02 modal_m1">
            <p class="font_14 modal_label" style="color: #fff">空空：</p>
            <Button type="primary" size="large" class="btn_border" ghost @click="answerAdd">+添加</Button>
          </div>
          <div class="flex02 modal_m1" v-if="type==0">
            <p class="font_14 modal_label">答案：</p>
            <Select v-model="isTrue" style="width:200px" placement="top">
              <Option v-for="(item,index) in answerList" :value="index+1" :key="index">选项{{index+1}}</Option>
            </Select>
          </div>
          <div class="flex05 modal_m1">
            <p class="font_14 modal_label">解析：</p>
            <div class="flexW1">
              <div id="chooseBox2" ref="chooseBox2">
                <div v-html="content.parseContent" v-if="chooseType == 1"></div>
                <div v-html="addChooseCon.parseContent" v-else></div>
            </div>
            </div>
          </div>
        </div>
        <div class="flex04 btn_wrapper">
          <Button type="primary" size="large" class="btn_border" ghost @click="modal_show1 = false" >取消</Button>
          <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px" @click="submitChoose">提交</Button>
        </div>
      </div>
    </Modal>
    
  </div>
</template>

<script>
import E from "wangeditor";
import { uploadApi,queryChooseProblemApi,chooseProblemDetailApi,deleteChooseProblemApi,editorCreate,showToast,chooseUpdateApi} from "@/api/index.js";
export default {
  data() {
    return {
      subId:this.$route.query.id,
      value: "",
      modal_show1: false,
      modal_show2: false,
      ruleForm:{
        title:''
      },
      answerList:[{item:''}],
      isTrue:null,
      content:{title:'',parseContent:''},
      type:0, //选择，1-填空
      list:[], //题目列表
      addChooseCon:{
        items:[],
        parseContent:'',
        title:''
      },
      chooseType:0, //选择题，0-添加，1-编辑
      fillType:0, //填空题，0-添加，1-编辑
      title:'',
      list1:[],
      list2:[],
      problemSetsId: "1442107812355280898",
    };
  },
  mounted() {
    editorCreate(this,'chooseBox1')
    editorCreate(this,'chooseBox2')
    this.getList()
    
  },
  methods: {
    // 删除
    del(conItem,index){
      // console.log(this.list)
      // let list = this.list,listItem = list[index]
      // listItem = listItem.filter(n=>n.id != conItem.id)
      // this.list[index] = listItem
      // return
      this.$Modal.confirm({
        title: '提示',
        content: '确认删除该题吗',
        onOk: () => {
          deleteChooseProblemApi({chooseProblemId:conItem.id}).then(res=>{
            showToast(this,'删除成功',1)
            this.getList()
          })
        },
        onCancel: () => {

        }
      });
      
    },
    // 选择题====0-添加,1-编辑 
    chooseAdd(type,item) {
      let title = '';
      if(item.type == 0){
        type == 0 ? title = '添加选择题' : title = '编辑选择题'
      }else{
        type == 0 ? title = '添加填空题' : title = '编辑填空题'
      }
      this.title = title
      this.chooseType = type;
      this.type = item.type;
      if(type === 0){
        this.answerList = [{item:'',isTrue:0}]
      }else{
        this.content = item;
        this.answerList = this.content.items;
        this.isTrue = this.answerList.find(n => n.isTrue == 1) ? this.answerList.find(n => n.isTrue == 1).isTrue : null
      }
      this.modal_show1 = true;
    },
    submitChoose(type) {
      let data = {
        title:this.getHtml('chooseBox1'),
        items:[],
        parseContent:this.getHtml('chooseBox2'),
        type:this.type
      }
      if(this.getText('chooseBox1') === '\n') return showToast(this,'标题不能为空');
      for(let i=0; i<this.answerList.length; i++){
        if(this.answerList[i].item.length === 0){
          showToast(this,this.type == 0 ? '选项' : '答案'+(i+1)+'不能为空');
          return false
        }
      }
      let answerList = this.answerList.map((m,index)=>{
        this.isTrue && index === this.isTrue-1 ? m.isTrue = 1 : m.isTrue = 0;
        return m
      })
      if(!this.isTrue && this.type == 0) return showToast(this,'请选择本题的答案')
      if(this.getText('chooseBox1') === '\n') return showToast(this,'解析不能为空')
      data.items = answerList
      let tip = '添加成功'
      data.problemId = this.subId;
      if(this.chooseType === 1){
        data.id = this.content.id;
        tip = '编辑成功'
      }
      console.log(data)
      // return
      chooseUpdateApi(data).then(res=>{
        showToast(this,tip,1);
        this.modal_show1 = false;
        this.content = {title:'',parseContent:''}
        this.getList();
        
      })
    },
    // 获取编辑框内容
    getHtml(idName){
        let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0]
        return text ? text.innerHTML : ''
      },
    // 获取编辑框文本
    getText(idName){
        let text = document.getElementById(idName).getElementsByClassName('w-e-text')[0]
        return text ? text.innerText : ''
      },
    // 添加答案选项
    answerAdd(){
      if(this.answerList.length < 4){
        this.answerList = this.answerList.concat({item:''})
      }else{
        showToast(this,'最多只能添加四个选项')
      }
    },
    // 删除答案选项
    answerDel(index){
      this.answerList = this.answerList.filter((n,idx) => index != idx)
    },
    getList(){
      queryChooseProblemApi({problemId:this.subId}).then(res=>{
        let list1 = res.filter(n=>n.type==0) || [''];
        let list2 = res.filter(n=>n.type==1) || [''];
        if(list1){
          list1.map(m => {
            let index = m.items.findIndex(n=> n.isTrue == 1);
            m.isTrueIdx = index
            return m
          })
        }
        this.list = [list1,list2];
      })
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ivu-modal-body{max-height: 80vh; overflow-y: auto}
.edit_m1 {margin: 18px 0;}
.edit_m3 {margin-right: 50px;}
.edit_title {margin: 16px 0 0 27px;}
.content {padding-bottom: 16px;}
.choose_wrapper {width: 928px;}
.label {width: 69px;text-align: right;}
.choose_title_btn {
  p {
    cursor: pointer;

    &:first-child {
      color: #355e7e;
      margin-right: 15px;
    }
  }
}

.edit_btn {
  margin: 16px 0 16px 27px;
}

.edit_btn_wrapper {
  width: 928px;
  margin: 35px 0;
}

.modal_label {
  width: 100px;
  text-align: right;
  margin: 0 11px 0 0;
}

.modal_m1 {
  margin: 20px 0;
}

.modal_icon {
  width: 18px;
  height: 18px;
  display: block;
  cursor: pointer;
  margin-left: 18px;
  background: url(../../assets/image/n.png) no-repeat center;
  background-size: 100% 100%;
}

.modal_wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);

  .modal_content {
    width: 900px;
    padding: ;
    margin: 50px auto;
  }
}
</style>
