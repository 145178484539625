<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <div class="re">
        <Title name="题目详情"></Title>
        <div class="run" v-if="content && content.copyNumber > 0 && content.copyNumber > content.haveCopiedNumber  || lastChooseProblemResult == -1">
          还有未完成的抄写
        </div>
      </div>
      <template v-if="content">
        <Tabs :value="tabName" @on-click="link">
          <TabPane label="题目" name="timu">
            <dl class="timu_wrapper topBox" :class="tabName === 'timu' ? 'on' : ''">
              <dt class="list">
                <div class="list_title">
                  <p class="font_20" style="font-size: 20px; font-weight: bold">
                    {{ content.title }}
                  </p>
                </div>
                <div class="font_14 color_666" v-html="content.notes"></div>
              </dt>
            </dl>
            <div class="timu_wrapper">
              <dl class="inputOut list-line ">
                <dd class="list">
                  <div class="list_title flex02">输入描述</div>
                  <div class="con" v-html="content.inputNotes"></div>
                </dd>
                <dd class="list">
                  <div class="list_title">输出描述</div>
                  <div class="con" v-html="content.outNotes"></div>
                </dd>
              </dl>
            </div>
            <div class="timu_wrapper">
              <dl class="inputOut list-line " v-for="(item, index) in sampleList">
                <dd class="list">
                  <div class="list_title flex02">
                    输入样例 {{ index + 1 }}
                    <Button type="primary" size="large" class="btn_border more" v-clipboard:copy="item.sampleInputTxt" v-clipboard:success="copyOk"  v-clipboard:error="copyError">复制</Button>
                  </div>
                  <pre class="con" style="" v-html="item.sampleInput"></pre>
                </dd>
                <dd class="list">
                  <div class="list_title">输出样例 {{ index + 1 }}</div>
                  <pre class="con" v-html="item.sampleOut"></pre>
                </dd>
              </dl>
            </div>
            
          </TabPane>
          <TabPane label="提交" name="tijiao">
            
            <v-success v-if="tijiaoAnswerEd" @close_success="tijiaoAnswerEd = false" :score="result_val" :ani="true" :list="tijiaoList"></v-success>
            <div class="flex-bect tijiao-box" v-else>
              <div class="textarea_wrapper topBox w1" :class="tabName === 'tijiao' ? 'on' : ''">
                <div class="text_wrapper">
                  <textarea v-model="submitWorkContent" placeholder="请输入你的答案"></textarea>
                </div>
                <div class="flex04 btn_wrapper">
                  <Button type="primary" size="large" class="btn_bg" @click="submitWork()">提交</Button>
                </div>
              </div>
              <div class="font_14 w2">
                <div class="h3">历史记录</div>
                <div>
                  <Table height="408" :columns="tijiaoAnswercolumns" @on-row-click="getCurHistoryItem" :data="tijiaoAnswerList"></Table>
                  
                </div>
              </div>
            </div>
            
          </TabPane>
          <TabPane label="改错题" name="gaicuoti">
            <div class="topBox" :class="tabName === 'gaicuoti' ? 'on' : ''">
              <div class="textarea_wrapper" v-if="errorProblemData && errorProblemData.id">
                <div class="text_wrapper" :class="{ active: abandon_btn === 2 }">
                  <textarea placeholder="" v-model="copyAnswer"></textarea>
                </div>
                <div class="flex04 btn_wrapper">
                  <div @click="giveup(0)" @mouseenter="giveup(1)" @mouseleave="giveup(2)">
                    <!-- -->
                    <Button type="primary" size="large" class="btn_border topic_m1" @click="showAnswer = true" v-if="loginType == 0">放弃</Button>
                  </div>
                  <!-- -->
                  <Button type="primary" size="large" class="btn_border topic_m1" @click="getErrorLookAnswer(0)" v-if="loginType == 2">编辑标准答案</Button>
                  <Button type="primary" size="large" class="btn_border topic_m1" @click="getErrorLookAnswer(1)" v-if="showAnswer">标准答案</Button>
                  <Buttontype="primary" size="large" class="btn_bg" @click="errorSubmitWork">提交</Button>
                </div>
              </div>
              <div class="noTip" v-else>暂无改错题</div>
            </div>
          </TabPane>
          <TabPane label="选择填空" name="xuanzetiankong">
            <div class="topBox" :class="tabName === 'xuanzetiankong' ? 'on' : ''" >
              <div>
                <div style="padding: 30px; color: #666" v-if="problemList.length === 0">该题目没有选择填空题</div>
                
                <div class="flex01 gap_start_wrapper" v-if="!exercise_show && problemList.length > 0">
                  <h2 v-if="lastChooseProblemResult == -1" style="padding-bottom: 230px; text-align: center;">您有罚写未完成，请先进行罚写</h2>
                  <Button v-else type="primary" size="large" class="btn_bg"  @click="exercise_show = true">开始做题</Button>
                  <!-- <p class="text_c">您还没有提交代码</br>请先提交代码</p> -->
                </div>
                
                <div class="font_14 exercise_wrapper" v-if="exercise_show">
                  <div v-for="(item, index) in problemList" :key="index" v-show="problemIndex === index" style="margin-bottom: 20px">
                    <p class="bold_ exercise_m1">问题{{ index + 1 }}：</p>
                    <div class="exercise_m2" v-html="item.title"></div>
                    {{ item.answer }}
                    <!-- 选择题 -->
                    <div v-if="item.type == 0">
                      <RadioGroup @on-change="getAnswerRadio($event, item, index)" >
                        <Radio :label="radioItem.id" v-for="(radioItem, radioIdx) in item.items" :key="radioIdx">
                          <span v-if="radioIdx == 0">A:</span>
                          <span v-if="radioIdx == 1">B:</span>
                          <span v-if="radioIdx == 2">C:</span>
                          <span v-if="radioIdx == 3">D:</span>
                          <span v-if="radioIdx == 4">E:</span>
                          <span v-if="radioIdx == 5">F:</span>
                          <span v-if="radioIdx == 6">G:</span>
                          {{ radioItem.item }}
                        </Radio>
                      </RadioGroup>
                    </div>
                    <!-- 填空题 -->
                    <dl class="gap_wrapper" v-if="item.type == 1">
                      <dd v-for="(radioItem, radioIdx) in item.items" :key="radioItem.id">
                        <span>填空{{ radioIdx + 1 }}</span>
                        <div class="t">
                          <Input v-model="radioItem.answerMyself" type="text" :rows="10" placeholder=""/>
                        </div>
                      </dd>
                    </dl>
                    <!-- 解析 -->
                    <dl class="gap_wrapper" v-if="!chooseAnswer">
                      <dt class="p1">
                        答案:
                        <template v-if="item.type == 0">
                          <span v-for="(radioItem, radioIdx) in item.items" :key="radioItem.id" style="float: none">
                            <template v-if="radioIdx == 0 && radioItem.isTrue == 1">A</template>
                            <template v-if="radioIdx == 1 && radioItem.isTrue == 1">B</template>
                            <template v-if="radioIdx == 2 && radioItem.isTrue == 1">C</template>
                            <template v-if="radioIdx == 3 && radioItem.isTrue == 1">D</template>
                            <template v-if="radioIdx == 4 && radioItem.isTrue == 1">E</template>
                            <template v-if="radioIdx == 5 && radioItem.isTrue == 1">F</template>
                            <template v-if="radioIdx == 6 && radioItem.isTrue == 1">G</template>
                          </span>
                        </template>
                      </dt>
                      <template v-if="item.type == 1">
                        <dd v-for="(radioItem, radioIdx) in item.items" :key="radioItem.id">
                          <span>填空{{ radioIdx + 1 }}: </span>
                          <div class="t">{{ radioItem.item }}</div>
                        </dd>
                      </template>
                      <dt>
                        <p class="p1">解析:</p>
                        <div v-html="item.parseContent"></div>
                      </dt>
                      <dd class="answer-ani cur" @click="link('chaoxie')">答案错误，请罚写后，继续答题</dd>
                    </dl>
                  </div>
                  <div v-if="chooseAnswer">
                    <Button @click="showNext()" style="margin: 30px 0" class="btn_bg" size="large" type="primary">
                      <span v-if="problemIndex === problemList.length - 1">提交</span>
                      <span v-else>下一题</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="笔记" name="biji">
            <div class="topBox" :class="tabName === 'biji' ? 'on' : ''">
              <div class="textarea_wrapper" v-if="loginType == 0">
                <div class="text_wrapper">
                  <textarea placeholder="" v-model="notes"></textarea>
                </div>
                <div class="flex04 btn_wrapper">
                  <Button type="primary" size="large" class="btn_bg" @click="noteSubmit(0)" >提交</Button>
                    
                </div>
              </div>
              <div v-if="loginType != 0 && bijiList.length > 0">
                <div
                  class="textarea_wrapper" v-for="(item, index) in bijiList" :key="index">
                  <Form :label-width="80">
                    <FormItem :label="item.studentName">
                      <div
                        style="display: flex;align-items: flex-start;justify-content: center;">
                        <Input v-model="item.notes" type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" />
                        <Button @click="noteSubmit(1, item)" style="margin-left: 20px">修改</Button>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="答案" name="daan">
            <div class="topBox" :class="tabName === 'daan' ? 'on' : ''" v-if="lookBookCon1">
              <template v-if="problemAnswer && problemAnswer.answerContent">
                <div class="textarea_wrapper" :class="problemAnswer.answerContent ? '' : 'h99'">
                  <img :src="problemAnswer.answerContent" alt="">
                </div>
              </template>
              <div v-else class="textarea_wrapper h99">没有设置答案</div>
            </div>
          </TabPane>
          <TabPane label="讲解" name="jiangjie">
            <div class="explain_wrapper topBox" :class="tabName === 'jiangjie' ? 'on' : ''" v-if="lookBookCon2">
              <div v-if="content.explainLink">
                <video class="video_wrapper" :src="content.explainLink" controls="controls"></video>
                <div class="explain_btn_wrapper flex04">
                  <div class="flex01 explain_btn" @click="understand(content.id, 'yes')">
                    <p>听懂了</p>
                    <img src="../../assets/image/l@2x.png" />
                  </div>
                  <div class="flex01 explain_btn" @click="understand(content.id, 'no')">
                    <p>没听懂</p>
                    <img src="../../assets/image/m@2x.png" />
                  </div>
                </div>
              </div>
              <div class="noTip" v-if="!content.explainLink && loginType == 0">
                暂无讲解
              </div>
              <div class="topic_m2" v-if="loginType != 0">
                <p class="font_16">扇形图</p>
                <div id="echart1" style="width: 300px; height: 250px"></div>
                <Table border :columns="columns" :data="data"></Table>
                <Page :total="total" show-total v-if="total > 0" style="margin-top: 30px" @on-change="getLinkPage($event)" />
              </div>
            </div>
          </TabPane>
          <TabPane label="问老师" name="wenlaoshi">
            <div class="noTip" v-if="!content.qq || content.qq.length == 0">
              暂无老师
            </div>
          </TabPane>
          <TabPane label="抄写" name="chaoxie">
            <div class="topBox" :class="tabName === 'chaoxie' ? 'on' : ''">
              
              <div class="copy_wrapper flex05 hidden"  v-if="content && content.copyNumber > 0 && content.copyNumber > content.haveCopiedNumber  || lastChooseProblemResult == -1">
                <!-- v-else -->
                <div class="copy_wrapper_left font_12">
                  <p>抄写时要思考每句话意义，无脑抄写没有意义！</p>
                  <div class="copyContent">
                    <div class="copyContent_text copyContent_text1" :class="{ active: is_error }">
                      <v-code :value="content.copyContent"></v-code>
                    </div>
                    <div class="copyContent_text copyContent_text2" :class="{ active: is_error }" v-if="copyShow">
                      <v-code :value="copyContent" :yuandata="content.copyContent" @input="get_code" @keyup.enter="searchFile"></v-code>
                    </div>
                  </div>
                </div>
                <div class="copy_wrapper_right hidden">
                  <div class="font_14 copy_wrapper_right_top">
                    <p>题目名称：{{ content.title }}</p>
                    <p> 抄写次数：<template v-if="copyShowData">（{{ content.haveCopiedNumber }}/{{content.copyNumber }}）</template>
                    </p>
                    <p>打字速度：{{ sport }}/KPM</p>
                    <p>状态：抄写<template v-if="content.haveCopiedNumber < content.copyNumber">未</template><template v-else>已</template>完成</p>
                  </div>
                  <div class="font_14">
                    <p>排行榜</p>
                    <div>
                      <Table :columns="columns1" :data="data1"></Table>
                    </div>
                  </div>
                </div>
              </div>
            
              <template v-else>
                <div v-if="content.copyNumber === 0" style="padding: 50px; text-align: center"> 暂时没有抄写任务 </div>
                <div abort="" v-else-if="content.haveCopiedNumber === content.copyNumber" style="padding: 50px; text-align: center">抄写完成</div>
              </template>
            </div>
          </TabPane>
          <TabPane label="链接" name="lianjie">
            <div class="noTip" v-if="!content.scoreLinks || content.scoreLinks.length == 0">暂无链接</div>
          </TabPane>
          <!-- <TabPane label="PPT" name="ppt">
            <div class="noTip" v-if="!content.pptLink || content.pptLink.length == 0">暂无PPT</div>
          </TabPane>
          -->
          <TabPane label="编辑题目" name="editSub" v-if="loginType == 2"></TabPane>
          <TabPane label="编辑选择填空题" name="editChoose" v-if="loginType == 2"></TabPane>
        </Tabs>
      </template>
    </div>
    <!-- 标准答案 -->
    <Modal footer-hide v-model="answer_show" title="标准答案" class="modal_wrapper">
      <div>
        <div v-html="lookAnswer" v-if="lookAnswer"></div>
        <div v-else>暂无数据</div>
      </div>
    </Modal>
    <!-- 编辑标准答案 -->
    <Modal footer-hide v-model="answer_bshow" title="编辑标准答案" class="modal_wrapper">
      <div id="answerEdit">
        <Form ref="answerEdit" :model="answerEdit" :label-width="0">
          <FormItem label="" prop="ansewrOk">
            <Input type="textarea" v-model="answerEdit.correctContent"></Input>
          </FormItem>
        </Form>

        <div class="flex04 modal_btn_wrapper">
          <Button type="primary" size="large" class="btn_border" @click="answer_bshow = false">取消</Button>
          <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px" @click="updateCorrectContent">确认</Button>
        </div>
      </div>
    </Modal>
    <!-- 提交 -->
    <Modal v-model="giveup_show" title="提示" width="300" @on-ok="giveupSubmit">
      <div>
        <div class="modal_content flex02">
          <img src="../../assets/image/k.png" />
          <p>该题设置有罚写，您确定放弃吗？</p>
        </div>
      </div>
    </Modal>

    <!-- 提交后的得分情况 -->
    <Modal footer-hide v-model="result_show" title="" class="modal_wrapper">
      <img src="@/assets/image/result2.png" class="result-img result-img2" v-if="result_val < 60" />
      <img src="@/assets/image/result1.png" class="result-img result-img1" v-else />
      <div class="tc resut_con" :class="[result_val < 60 ? '' : 'gao']">
        <p class="p1"><b>{{ result_val }}</b><span>分</span></p>
        <p class="p2" v-if="result_val < 60">本次得分较低，请不要气馁，继续加油！</p>
        <p class="p2" v-else>恭喜您，本次得分较高，请再接再厉！</p>
      </div>
    </Modal>
    <!-- 申请讲解和答案 -->
    <Modal title="" class="modal_wrapper" v-model="lookAnswerBook" @on-ok="asyncOK">
      <p v-if="lookBook === 1">确定查看本题答案吗？</p>
      <p v-if="lookBook === 2">确定查看本题讲解吗？</p>
    </Modal>
    <!-- 提交 -->
    <Modal v-model="detail_show"  @on-cancel="tijiao_tabs_cur = 0" title="评测详情" class-name="detail_show" width="900">
      <div class="noTip" v-if="!CurHistoryItem.json">暂无数据</div>
      <div class="tijiao-detail" v-else>
        <dl class="tabs">
          <dd v-for="(item, index) in tijiao_tabs" @click="tijiao_tabs_cur=index" :class="{on:index == tijiao_tabs_cur}">{{item}}</dd>
        </dl>
        <!--  -->
        
        <div v-if="tijiao_tabs_cur == 0" class="list">
          <v-success v-if="tijiao_tabs_cur == 0" :list="CurHistoryItem.json"></v-success>
          <!-- <div class="reach_block" @click="getCurHistoryItem(item)" :class="{on:item.score < 1,orgen : item.result === -2, TLE : item.result === 7 , active:item.id == tijiaoHistoryCur}" v-for="(item, index) in CurHistoryItem.json" :key="index">
            <div>
              <p>#{{index+1}}</p>
              <p>
                <template v-if="CurHistoryItem.score != 100">
                  <template v-if="item.result === -2">CE</template>
                  <template v-else-if="item.result === 7">TLE</template>
                  <template v-else-if="item.score < 1">WA</template>
                  <template v-else>AC</template>
                </template>
                <template v-if="CurHistoryItem.score == 100">AC</template>
              </p>
              <p>{{item.time}}ms/{{item.memory}}MB</p>
            </div>
          </div> -->
        </div>
        <div class="tijiao-con" v-else>
          <pre>{{CurHistoryItem.content}}</pre>
        </div>
      </div>
      <div slot="footer">
        <Button type="primary" class="btn_border more" style="height: 40px; border: none;" v-clipboard:copy="CurHistoryItem.content" v-clipboard:success="copyOk"  v-clipboard:error="copyError" v-if="tijiao_tabs_cur === 1">复制代码</Button>
      </div>
    </Modal>
    
  
  </div>
</template>

<script>
// 改错题
// 点击放弃：1.如果该题设置有罚写，用户点击”放弃“会被罚抄写。2.没有设置，点击查看标准答案，移走鼠标展示错误代码
// 编辑标准答案：跳转页面；
// 标准答案：弹窗展示；
// 提交：如果内容和标签不一样，测提示“错误”代码并恢复初始的错误代码。
// 问老师：跳qq
// 连接：跳外链
// ppt：跳连接
import * as echarts from "echarts";
import success from "@/components/success/index.vue";
import code from "@/components/Code/index.vue";
import {
  queryProblemSetsByIdApi,submitWorkApi, errorSubmitWorkApi, getCourseErrorProblemApi, errorLookAnswerApi, updateCorrectContentApi, giveUpApi,
  showToast, rankByProblemIdApi, isLinkApi, linkPageApi, notesCommintApi, linkInfoApi, copyCommintApi, copyQueryApi, queryChooseProblemApi, workHistoryApi,
  chooseProblemWorkSubmitApi,studentNotesQueryApi,studentNotesSubmitApi,getNotesApi, lookExplainApi,lookAnswerApi, getProblemCopyNumbersApi,
} from "@/api/index.js";
let timeRun = null;
let chaoxieTimeRun;
export default {
  components: {
    "v-code": code,
    "v-success": success,
  },
  data() {
    return {
      
      loginType: localStorage.getItem("loginType"),
      user: JSON.parse(localStorage.getItem("user")),
      value: "",
      is_error: false,
      exercise_show: false,
      answer_show: false,
      answer_bshow: false,
      abandon_btn: 1,
      abandon_text: "",
      abandon_show: false,
      showAnswer: false, //点击放弃后才显示标准答案的按钮
      error_text: "left: 250;\ntop: 382 px;",
      answer_text: "left: 250px;\ntop: 382 px;",
      answerEdit: {
        correctContent: "",
        errorProblemId: "",
      },
      columns: [
        {width: 90,title: "排名",key: "index",className: "table-head-style"},
        {title: "学员",key: "userName",className: "table-head-style"},
        {title: "状态",key: "statusTxt",className: "table-head-style"},
        {title: "时间",key: "createTime",className: "table-head-style"},
      ],
      data: [],
      columns1: [
        {title: "排行",key: "paihang",className: "table-head-style"},
        {title: "姓名",key: "studentName",className: "table-head-style"},
        {title: "速度",key: "useTime",className: "table-head-style"},
      ],
      data1: [
        {paihang: "1",studentName: "小明",useTime: "5msk/up"},
      ],

      content: null, //详情内容
      submitWorkContent: "", //答案
      copyAnswer: "", //改错题提交内容
      total: 0,
      pageNum: 1,
      notes: "", //笔记体提交内容

      copyShowData: null, //抄写栏目的数据
      copyShow: true,
      copyCurNum: 0, //本次抄写次数
      copyContent: "", //抄写内容
      timeLock: null, //打字监听时间
      timer: false,
      timeIng: 1,
      sport: 0, //打字速度

      is_not_finish_status: true,

      problemList: [], //选做题列表
      problemIndex: 0,
      jobId: this.$route.query.jobId, //作业id
      answerEd: false,

      bijiList: [], //笔记列表---老师和超管都能改 ,学生只能看和改自己的

      linkIds: this.$route.query, //相关参数
      errorProblemData: null, //改错题详情
      lookAnswer: null, // 改错题标准答案
      copyStatus: false, //放弃是否要罚写 1、如果该题需罚写，用户点击”放弃“会被罚抄写。2、如果该题没有罚写，学员如果点击“放弃”页面会出现“标准答案”，松开会有显示修改的错误代码。
      giveup_show: false,
      problemAnswer: null, //题目的答案
      classId: null, //班级id
      chooseAnswer: true, //选作填空题的答案是否正确
      chooseAnswerId: null, //选作的答案id
      tabName: "timu",
      result_show: false,
      result_val: 1,
      sampleList: [],
      lookBook: 9, // 1-查看答案弹窗，2-查看讲解弹窗
      lookBookCon1: false,
      lookBookCon2: false,
      lookAnswerBook: false, //申请查看
      tijiaoList: [],
      tijiaoAnswerEd:false, //是否提交
      
      echart1Data: [], //shan'x
      tijiaoAnswercolumns: [
        {title: "用户",key: "name",className: "table-head-style"},
        {title: "成绩",key: "score",className: "table-head-style"},
        {title: "提交时间",key: "createTime",className: "table-head-style"},
      ],
      tijiaoAnswerList:[], //提交答案的记录
      tijiaoAnswerMore:false, //是否超过10条
      tijiao_tabs:['测试点','源代码'],
      tijiao_tabs_cur:0,
      tijiaoHistoryList:[
        {result:-1,real_time:3,memory:20},
        {result:1,real_time:8,memory:14},
        {result:-1,real_time:31,memory:220},
        {result:1,real_time:38,memory:414},
        {result:-1,real_time:13,memory:202},
        {result:1,real_time:8,memory:14}
      ],
      tijiaoHistoryCur:0,
      CurHistoryItem:'', //当前的提交记录详情
      detail_show:false,
      
      answerResult:0, //选择填空提的答案是否正确
      lastChooseProblemResult:null, //选择填空题是否要抄写 ---   -1 错误 0 正确  null 就是没做过
      copyWriteShow:false,
    };
  },
  mounted() {
    this.abandon_text = this.error_text;
    this.linkIds = this.$route.query;
    this.linkIds.studentId = localStorage.getItem("userId");
    // this.linkIds.setId = '1460535287078739969'
    this.classId = this.linkIds.classId;
    this.getCon();
  },
  destroyed() {
    clearInterval(chaoxieTimeRun);
    clearInterval(timeRun);
  },
  created() {
    let that = this;
    document.onkeydown = function (e) {
      //事件对象兼容
      if (e.code === "Backspace" && that.copyContent.length === 0) {
        that.copyCurNum = 0;
      }
    };
  },
  methods: {
    // 点击提交历史记录数据
    getCurHistoryItem(e){
      e.score = Number(e.score)
      this.CurHistoryItem = e;
      this.tijiaoHistoryCur = e.id;
      this.detail_show = true;
      // console.log('CurHistoryItem',e)
    },
    getPaiH() {
      rankByProblemIdApi({
        problemId: this.linkIds.setId,
      }).then((res) => {
        this.data1 = res.map((m, i) => {
          m.paihang = i + 1;
          return m;
        });
      });
      getProblemCopyNumbersApi({
        studentId: this.linkIds.studentId,
        problemId: this.linkIds.setId,
        courseId: this.linkIds.courseId,
      }).then((res) => {
        this.content.copyNumber = Number(res.copyNumber) || 0;
        this.content.haveCopiedNumber = Number(res.haveCopiedNumber) || 0;
      });
    },
    // 获取学员笔记
    getNotes() {
      getNotesApi({
        problemId: this.linkIds.setId,
        studentId: this.linkIds.studentId,
        courseId: this.linkIds.courseId,
      }).then((res) => {
        this.notes = res.notes;
      });
    },
    // 改错题放弃提交
    giveupSubmit() {
      giveUpApi({
        errorProblemId: this.errorProblemData.id,
        problemId: this.linkIds.setId,
      }).then((res) => {
        showToast(this, "成功放弃该题", 1);
      });
    },
    // 改错题放弃---copyStatus--true有惩罚,false-无惩罚
    giveup(type) {
      if (type === 0 && this.copyStatus === true) {
        this.giveup_show = true;
        this.abandon_btn = 2;
      }
      if (type === 1) {
        this.copyAnswer = this.answerEdit.correctContent;
        this.abandon_btn = 1;
      } else if (type === 2) {
        this.copyAnswer = this.errorProblemData.errorContent;
      }
    },
    // 获取纯文本
    getTxt(s) {
      var dd1 = s.replace(/\n/g, " ");
      var dd2 = dd1.replace(/<br>/g, "\n");
      var dd3 = dd2.replace(/<br\/>/g, "\n");
      var dd4 = dd3.replace(/<\/?.+?\/?>/g, "");
      var dds = dd4.replace(/ /g, ""); //dds为得到后的内容
      dds = dd4.replace(/&nbsp;/g, "");
      return dds;
    },
    getCon() {
      // 获取题目详情
      queryProblemSetsByIdApi({
        setId: this.linkIds.setId,
        studentId: this.linkIds.studentId,
        courseId: this.linkIds.courseId,
      }).then((res) => {
        if (res) {
          this.lastChooseProblemResult = res.lastChooseProblemResult
          res.problem.copyNumber = Number(res.problem.copyNumber) || 0;
          res.problem.haveCopiedNumber =
            Number(res.problem.haveCopiedNumber) || 0;
          this.content = res.problem;
          this.content.inputNotesTxt = this.getTxt(res.problem.inputNotes);
          this.sampleList = res.sampleList.map((m) => {
            // console.log("m.sampleInput", m.sampleInput);
            m.sampleInputTxt = this.getTxt(m.sampleInput);
            return m;
          });
          this.problemList = res.chooseProblemList || [];
          this.problemAnswer = res.problem;
          linkInfoApi({
            linkId: this.linkIds.setId,
          }).then((linkRes) => {
            let y = 0,
              no = 0;
            if (linkRes.length > 0) {
              linkRes.forEach((item) => {
                if (item.status === "yes") {
                  y = y + parseInt(item.num);
                }
                if (item.status === "no") {
                  no = no + parseInt(item.num);
                }
              });
            }
            // console.log("y,no", y, no);
            this.echart1Data = [y, no];
          });

          if (this.loginType == 0) {
            this.getNotes();
          }
        }
      });

      // 获取改错题
      getCourseErrorProblemApi({
        problemId: this.linkIds.setId,
      }).then((res) => {
        if (res && res.id) {
          this.errorProblemData = res;
          this.answerEdit.errorProblemId = res.id;
          this.copyAnswer = res.errorContent;
          this.getErrorLookAnswer();
        }
      });

      // 抄写查询
      copyQueryApi({
        userId: localStorage.getItem("userId"),
        problemId: this.linkIds.setId,
      }).then((res) => {
        this.copyShowData = res;
      });

      // 获取笔记
      studentNotesQueryApi({
        problemId: this.linkIds.setId,
        classId: this.linkIds.classId,
      }).then((res) => {
        this.bijiList = res;
      });
    },
    // 提交
    submitWork() {
      let datas = {
        courseId: this.linkIds.courseId,
        studentId: this.linkIds.studentId,
        studentScoreId: this.linkIds.studentScoreId,
        content: this.submitWorkContent,
      };
      if (this.submitWorkContent === "") return showToast(this, "作业不能为空");
      const msg = this.$Message.loading({
        content: "正在抓紧评分....",
        duration: 0,
      });
      submitWorkApi(datas).then((res) => {
        let data = JSON.parse(res.info).data;
        this.tijiaoAnswerEd = true;
        this.submitWorkContent = '';
        this.result_val = Number(res.score);
        setTimeout(()=>{
          this.tijiaoAnswerEd = false;
        },5000)
        this.getSubmitWorkList();
        // console.log('submitWorkAp====>作业提交后的返回的数据-res',res)
        // console.log('submitWorkAp====>作业提交后的返回的数据-data',JSON.parse(res.info))
        if (data) {
          this.tijiaoList = data.map((m) => {
            m.memory = (m.memory / 1024 / 1024).toFixed(2);
            return m;
          });
        }else{
          this.tijiaoList = [{score:0, result:JSON.parse(res.info).result || -2,memory:0,real_time:'0'}];
        }
      });
    },
    // 改错题提交
    errorSubmitWork() {
      let data = {
        studentId: this.linkIds.studentId,
        problemId: this.linkIds.setId,
        errorProblemId: this.errorProblemData.id,
        copyAnswer: this.copyAnswer,
      };
      // console.log("errorSubmitWorkApi");
      // return
      if (data.copyAnswer.length === 0)
        return showToast(this, "请输入你的改错题答案");
      errorSubmitWorkApi(data).then((res) => {
        // console.log("res===>", res);
        let tit = res ? "回答正确" : "回答错误";
        showToast(this, tit, 1);
      });
    },
    getErrorLookAnswer(type) {
      if (type === 1) {
        this.answer_show = true;
      } else if (type === 0) {
        this.answer_bshow = true;
      }
      // 获取改错题标准答案
      errorLookAnswerApi({
        errorProblemId: this.errorProblemData.id,
        problemId: this.linkIds.setId,
      }).then((look) => {
        this.lookAnswer = look;
        this.answerEdit.correctContent = look;
      });
    },

    showNext() {
      let problemList = this.problemList;
      let problemIndex = this.problemIndex;

      let item = problemList[problemIndex];
      let data = {
        studentId: this.linkIds.studentId,
        problemId: this.linkIds.setId,
        chooseProblemId: item.id,
        result:this.answerResult,
        itemIds: [],
        courseId: this.linkIds.courseId
      };
      // console.log('itemitemitem',item.items)
      if (item.type == 1) {
        let num = 0;
        item.items.forEach((n) => {
          if (n.answerMyself && n.answerMyself.length > 0) {
            num++;
          }
        });
        if (num < item.items.length) return showToast(this, "请先完成本道题");
        data.itemIds = item.items.map((m) => {
          return m.answerMyself;
        });
        let curAnswer = item.items.map((m) => {
          return m.item;
        });
        // console.log(curAnswer, data.itemIds);
        let answerNum = 0;
        for (let i = 0; i < curAnswer.length; i++) {
          if (curAnswer[i] == data.itemIds[i]) {
            answerNum++;
          }
        }
        // 如果答案一致说明答对否则答错
        if (answerNum === curAnswer.length) {
          this.getAnswer(1, data);
        } else {
          this.getAnswer(0, data);
        }
      } else {
        if (!this.chooseAnswerId) return showToast(this, "请先完成本道题");
        data.itemIds = [this.chooseAnswerId]
        let isTrueCon = item.items.find((n) => n.isTrue == 1);
        if (isTrueCon && this.chooseAnswerId === isTrueCon.id) {
          this.getAnswer(1, data);
        } else {
          this.getAnswer(0, data);
        }
      }
    },
    getAnswer(type, data) {
      let problemList = this.problemList;
      let problemIndex = this.problemIndex;
      let last = false
      // console.log("提交题目");
      if (type === 1) {
        // console.log("答案正确");
        if (problemIndex === problemList.length - 1) {
          this.answerEd = true;
          last = true;
        } else {
          this.problemIndex = this.problemIndex + 1;
          this.chooseAnswer = true;
          
        }
      } else if (type === 0) {
        this.chooseAnswer = false;
        this.lastChooseProblemResult = -1
        // console.log("答案错误");
      }
      this.chooseProblemWorkSubmit(data,last);
    },
    getAnswerRadio(e, item, index) {
      // console.log(e, item);
      this.answerResult = item.items.find(n => n.id == e).isTrue == 1 ? 0 : -1;
      this.chooseAnswerId = e;
      this.problemIndex = index;
      this.lastChooseProblemResult = this.answerResult === 1
      
    },
    chooseProblemWorkSubmit(data,last) {
      chooseProblemWorkSubmitApi(data).then((res) => {
        if(last){
          this.tabName = 'timu';
          this.exercise_show = false;
          this.problemIndex = 0;
        }
        
        // showToast(this,'恭喜完成本次作业',1);
        // this.answerEd = true;
      });
    },
    submitChoose(data) {
      chooseProblemWorkSubmitApi(data).then((res) => {
        showToast(this, "恭喜完成本次作业", 1);
        this.answerEd = true;
      });
    },
    // 监听键盘
    searchFile(e) {
      //console.log(e)
    },
    // 置换\n
    trim(s) {
      return s.replace(/(^\s*)|(\s*$)/g, "");
    },
    
    // 数据核对
    CopySpeed() {
      this.timeIng += 1;
      this.sport = parseInt((this.copyContent.length / this.timeIng) * 60);
    },
    // 笔记提交
    noteSubmit(type, item) {
      let tip = "";
      let data = {
        studentId: "",
        classId: this.classId,
        problemId: this.linkIds.setId,
        notes: "",
      };
      if (type === 0) {
        tip = "笔记提交成功";
        data.studentId = this.linkIds.studentId;
        data.notes = this.notes;
      } else {
        tip = item.studentName + "的笔记修改成功";
        data.studentId = item.studentId;
        data.notes = item.notes;
      }
      studentNotesSubmitApi(data).then((res) => {
        showToast(this, tip, 1);
      });
    },
    // 讲解---学员列表
    getLinkPage(e = 1) {
      this.pageNum = parseInt(e);
      linkPageApi({
        linkId: this.content.id,
        pageNum: this.pageNum,
        pageSize: 10,
      }).then((res) => {
        if (res.list.length > 0) {
          res.list.map((m, i) => {
            m.status === "yes"
              ? (m.statusTxt = "听懂了")
              : (m.statusTxt = "没有听懂");
            m.index = 10 * (this.pageNum - 1) + i + 1;
          });
          this.data = res.list;
        }

        this.total = parseInt(res.total);
      });
    },
    // 听懂没有
    understand(id, type) {
      let data = {
        userId: localStorage.getItem("userId"),
        userName: this.user.name,
        type: type,
        linkId: id,
      };
      isLinkApi(data).then((res) => {
        let tip = "听懂了",
          tipType = 1;
        if (type === "no") {
          tip = "没有听懂";
          tipType = 0;
        }
        showToast(this, tip, tipType);
      });
    },
    getSubmitWorkList(){
      let data = {
        studentId:this.linkIds.studentId,
        studentScoreId:this.linkIds.studentScoreId
      }
      let user = localStorage.getItem('user')
      let name = user ? JSON.parse(user).studentName : ''
      workHistoryApi(data).then(res=>{
        res.map((m,index)=>{
          m.name = name;
          m.result = Number(m.score);
          m.memory = (m.memoryCost/1024/1024).toFixed(2);
          m.time = m.timeCost || 0;
          m.id = index;
          let jsonTxt = JSON.parse(m.jsonTxt);
          let json = jsonTxt.info.data
          // console.log('jsonTxt==========>',jsonTxt)
          if(json){
            json.map(jsonItem => {
              jsonItem.result = Number(jsonItem.score);
              jsonItem.memory = (jsonItem.memory/1024/1024).toFixed(2);
              jsonItem.time = jsonItem.test_case || 0;
              return jsonItem
            })
          }else{
            // {memory: m.memory, time: m.time, result:m.result}
            json = [{result:jsonTxt.result || -2, memory:0, time:0, content:jsonTxt.code}]
          }
          m.json = json;
          return m
        })
        // console.log('result',res)
        this.tijiaoHistoryList = res;
        this.tijiaoAnswerList = res;
        // this.tijiaoAnswerList = res.filter((n, i)=> i < 10);
        this.tijiaoAnswerMore = res.length > 10
      })
    },
    taskDetail(){
      let item = this.linkIds;
      const routeUrl = this.$router.resolve({
        path: "/taskDetail",
        query: {
          studentId: item.studentId,
          courseId: item.courseId,
          studentScoreId: item.studentScoreId,
          classId: item.classId,
          title: this.content.title,
          problemId: item.problemId,
          from:2
        },
      });
      window.open(routeUrl.href, "_blank");
      // let url = '/topicDetail?studentId='+linkIds.studentId+'&=courseId'+linkIds.courseId+'&studentScoreId='+linkIds.studentScoreId+'&setId='+linkIds.setId+'&classId='+linkIds.classId;
      // console.log('sssss===>',url)
      // window.open(url);
    },
    link(e) {
      
      if (e === "lianjie" && this.content.scoreLinks){
        
      }else{
        this.tabName = e;
      }
      if (e !== "xuanzetiankong") {
        this.chooseAnswerId = null;
        this.problemIndex = 0;
        this.chooseAnswer = true;
        this.exercise_show = false;
        this.answerEd = false;
        this.problemList = this.problemList.map((m) => {
          m.items.map((m1) => {
            m1.answerMyself = null;
            return m1;
          });
          return m;
        });
      } else {
      }
      // this.content.dataLink = 'https://baidu.com'
      if(e === 'tijiao'){
        
        this.getSubmitWorkList()
      }
      else if (e === "wenlaoshi" && this.content.qq) {
        let url =
          "http://wpa.qq.com/msgrd?v=3&uin=" +
          this.content.qq +
          "&site=在线客服&menu=yes";
        window.open(url);
      } else if (e === "lianjie" && this.content.scoreLinks) {
        let url = this.content.scoreLinks;
        window.open(url);
      } else if (e === "ppt" && this.content.pptLink) {
        let url = this.content.pptLink;
        window.open(url);
      } else if (e === "editSub") {
        this.$router.push({
          path: "/EditTopic?id=" + this.linkIds.setId,
        });
      } else if (e === "editChoose") {
        this.$router.push({
          path: "/EditChoose?id=" + this.linkIds.setId,
        });
      } else if (e === "daan") {
        this.lookBook = 1;
        this.lookAnswerBook = !this.lookBookCon1;
      } else if (e === "jiangjie") {
        this.lookBook = 2;
        this.lookAnswerBook = !this.lookBookCon2;
      }
      if (e === "chaoxie" && this.content.copyNumber > 0) {
        chaoxieTimeRun = setInterval(() => {
          this.getPaiH();
        }, 500);
      } else {
        clearInterval(chaoxieTimeRun);
      }
    },
    //申请答案和讲解的接口
    asyncOK() {
      let data = {
        studentId: this.linkIds.studentId,
        studentScoreId: this.linkIds.studentScoreId,
      };
      // 1-答案,2-讲解
      if (this.lookBook === 1) {
        this.lookBookCon1 = true;
        lookAnswerApi(data).then((look) => {
          this.lookAnswer = look;
          // this.answerEdit.correctContent = look;
        });
      } else if (this.lookBook === 2) {
        this.lookBookCon2 = true;
        this.getLinkPage();
        if (this.loginType != 0) {
          setTimeout(() => {
            let [y, no] = this.echart1Data;
            this.get_echart1(y, no);
          }, 500);
        }
      }
    },
    //复制成功
    copyOk(e) {
      showToast(this, "复制成功", 1);
    },
    // 复制失败
    copyError(e) {
      showToast(this, "复制失败", 0);
    },

    // 编辑标准答案
    updateCorrectContent() {
      let data = this.answerEdit;
      if (data.correctContent === "")
        return showToast(this, "标准答案不能为空");
      updateCorrectContentApi(data).then((res) => {
        showToast(this, "标准答案编辑成功", 1);
        this.answerEdit.correctContent = "";
        this.answer_bshow = false;
      });
    },

    get_ok() {},

    get_echart1(a, b) {
      // console.log("myChart", document.getElementById("echart1"));
      var myChart = echarts.init(document.getElementById("echart1"));
      var option = {
        title: {
          text: "",
          subtext: "",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#355E7E", "#E3AC30"],
        legend: {
          show: false,
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: "50%",
            data: [
              {
                value: b,
                name: "没听懂",
              },
              {
                value: a,
                name: "听懂了",
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
    get_code(e) {
      //计算打字速度
      var copyContent = e.con;
      this.copyContent = copyContent;
      if (copyContent == "") {
        clearInterval(timeRun);
        this.timer = false;
        this.timeIng = 1;
      } else if (copyContent.length == 1) {
        if (!this.timer) {
          this.timer = true;
          clearInterval(timeRun);
          timeRun = setInterval(() => {
            //数据核对
            this.CopySpeed();
          }, 1000);
        }
      }
      //抄写完成后的操作
      this.is_error = !e.cur;
      if(e.ok && !this.is_error){
        copyCommintApi({
          userId: localStorage.getItem("userId"),
          problemId: this.linkIds.setId,
          copyTime: this.sport,
          userName: localStorage.getItem("user").name,
        }).then((res) => {
          this.content.haveCopiedNumber = this.content.haveCopiedNumber + 1;
          this.copyShow = false;
          this.copyContent = "";
          setTimeout(() => {
            this.copyShow = true;
          }, 10);
          showToast(this, "您完成了一遍抄写", 1);
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.inputOut{
  display: flex;align-items: stretch; justify-content: space-between; padding-bottom: 0px;
  .list{width: 48%;}
  .con{padding: 16px;font-size:14px; color:#666; height: calc(100% - 40px); border-radius:4px; overflow:hidden; background-color: #fcfcfc; min-height: 80px;}
  .list_title{font-size:16px; font-weight:bold}
}
.detail_show .ivu-modal-footer{display:none !important}
.tijiao-box /deep/.table-head-style{cursor: pointer;}
.tijiao-detail{
  padding: 0 20px;
  .tijiao-con{
    padding: 10px;border: 1px solid #ededed;border-radius: 5px; min-height: 300px;
    pre{font-size: 14px; font-family: '微软雅黑';word-wrap: break-word; white-space: pre-wrap;}
  }
  .tabs{
    display: flex;align-items: center; margin-bottom: 20px;
    dd{
      width: 130px; line-height: 40px; text-align: center; background-color: #f9f9f9; margin-right: 10px; border-radius: 4px; cursor: pointer;
      &.on{font-weight: bold; background-color: #7786cb; color: #fff;}
    }
  }
  .list {
    display: flex; min-height: 300px;flex-wrap: wrap;position: relative; align-items: flex-start;
    .reach_block {
      color: #fff;
      padding: 8px;
      width: 120px;
      margin: 0 15px 10px 0;
      text-align: center;
      background-color: #32cd32;
      animation: shake 800ms ease-in-out; cursor: pointer;
      &.on{background-color: red;}
      &.active{ box-shadow: 0 0 10px #999; }
      &.orgen{background-color: orange !important;}
      &.TLE{background-color: #052242;}
      p {
        &:first-child {
          text-align: left;
        }
  
        &:nth-child(2) {
          font-size: 20px;
          margin: 8px 0;
        }
  
        &:last-child {
          margin-bottom: 14px;
        }
      }
    }
  }
  
}
.tijiao-box{
  display: flex; 
  .w1{width: 50%;}
  .w2{width: 36%; margin-left: 4%;
    .h3{font-weight: bold; font-size:16px; margin:27px 0 10px;}
    /deep/.ivu-table td{height:36px}
    /deep/.ivu-table-wrapper{border: 1px solid #ededed;}
    /deep/.ivu-table-overflowX{overflow-x: hidden;}
  }
}
.re {
  position: relative;
}

.run {
  position: absolute;
  left: 150px;
  top: 0;
  line-height: 60px;
  font-size: 12px;
  color: #f07d00;
  animation: textRun 0.5s infinite;
}

@keyframes textRun {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.result-img {
  display: block;
  margin: 0 auto;
  margin-top: -86px;
}

.result-img1 {
  width: 187px;
}

.result-img2 {
  width: 150px;
}

.resut_con.gao .p1 {
  color: #f07d00;
}

.resut_con {
  text-align: center;

  .p1 {
    color: #7786cb;
    margin: 20px 0;
  }

  .p1 b {
    font-size: 60px;
  }

  .p2 {
    font-size: 16px;
    margin-bottom: 40px;
  }
}

.topic_m1 {
  margin-right: 10px;
}

.topic_m2 {
  margin-top: 57px;
}

.timu_wrapper {
  overflow: hidden;
  padding: 20px; padding-top: 0;
  max-width: 1000px;
}

.timu_wrapper dt {
  padding-top: 20px;
}

.list {
  padding: 10px 0;
}

.list-line {
  border-top: 1px solid #ededed;
  padding-top: 20px;
  margin-top: 10px;
}

.list_title {
  margin-bottom: 12px;
}

.textarea_wrapper {
  width: 820px;
  margin: 27px 0 0 27px;
  img{max-width: 100%;}
  .text_wrapper {
    padding: 14px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #d4d4d4;

    &.active {
      textarea {
        color: #355e7e;
      }
    }

    textarea {
      width: 100%;
      height: 336px;
      resize: none;
    }
  }

  .btn_wrapper {
    margin: 24px 0 47px;
  }
}

#answerEdit /deep/ textarea.ivu-input {
  min-height: 300px;
}

.textarea_wrapper /deep/ textarea.ivu-input {
  min-height: 100px !important;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab-active {
  color: #355e7e;
}

.box /deep/ .ivu-tabs-ink-bar {
  background-color: #355e7e;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #355e7e;
}

.box /deep/ .ivu-tabs-nav-scroll {
  padding: 0 0 0 0;
}

.box /deep/ .ivu-tabs-bar {
  margin-bottom: 0;
  border-color: #ebeef5;
}

.box /deep/ .ivu-tabs-nav .ivu-tabs-tab {
  padding-top: 15px;
  padding-bottom: 15px;
}

.modal_wrapper /deep/ .ivu-modal-header {
  border: none;
}

.modal_content {
  color: #999;
  font-size: 12px;
  line-height: 16px;

  img {
    height: 19px;
    margin-right: 6px;
  }
}

.modal_btn_wrapper {
  margin: 38px 0 0 0;
}

.modal_des {
  color: #f76767;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 10px;
}

.gap_start_wrapper {
  width: 820px;
  height: 500px;
}

.explain_wrapper {
  width: 766px;
  margin: 27px;

  .video_wrapper {
    width: 100%;
    display: block;
    border: 1px solid #eee;
  }

  .explain_btn_wrapper {
    margin-top: 22px;
  }

  .explain_btn {
    width: 100px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(53, 94, 126, 0.06);

    &:last-child {
      margin-left: 30px;
    }

    img {
      height: 16px;
      margin-left: 7px;
    }
  }
}
.btn_border.more{height: 28px; min-width: 64px; margin-left: 14px}
/deep/ .ivu-table th.table-head-style {
  padding: 12px 0;
  background-color: #ffffff;
}

.exercise_wrapper {
  margin: 27px;

  .exercise_m1 {
    margin: 0 0 10px 0;
  }

  .exercise_m2 {
    margin: 0 0 7px 0;
  }

  /deep/ .ivu-radio-wrapper {
    display: block;
    margin: 10px 0;
  }

  /deep/ .ivu-radio-checked .ivu-radio-inner {
    border-color: #355e7e;
  }

  /deep/ .ivu-radio-inner {
    margin-right: 13px;
  }

  /deep/ .ivu-radio-inner:after {
    background-color: #355e7e;
  }

  .gap_wrapper {
    width: 800px;
    margin: 10px 0 0 0;

    /deep/ textarea {
      resize: none;
    }

    dd {
      width: 100%;
      overflow: hidden;
      margin-bottom: 10px;
      line-height: 30px;
    }

    span {
      float: left;
      margin-right: 10px;
    }

    .t {
      float: left;
      width: 260px;
    }
  }
}

.copy_wrapper {
  padding: 0 27px;

  .copy_wrapper_left {
    width: 900px;
    margin-right: 46px;

    p {
      color: #e3ac30;
      margin: 10px 0;
    }
  }

  .copy_wrapper_right {
    flex: 1;
    color: #333;

    p {
      line-height: 25px;
    }
  }

  .copy_wrapper_right_top {
    margin: 46px 0 42px;
  }

  .copyContent {
    height: 1000px;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
    border: 1px solid #d4d4d4;

    .copyContent_text {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      font-size: 18px;
      position: absolute; z-index: 1;
    }
    .copyContent_text2{
      z-index: 998;
      /deep/.cm-s-cobalt .CodeMirror-gutters, /deep/.CodeMirror{
        background-color: transparent;
      }
    }
  }
}

.copyContent_text1 /deep/ .CodeMirror-line,.copyContent_text1 /deep/ .CodeMirror-gutter {
  // color: rgba(229, 97, 219, 0.5) !important;
  opacity: 0.4;
}

.copyContent_text.active {
  /deep/ .CodeMirror-code {
    &>div{background-color: rgba(255, 0, 0, 0.6);}
    // background-color: red;
  }
}

.h99 {
  color: #ccc;
}

.topBox {
  overflow: hidden;
  height: 0px !important;
}

.topBox.on {
  overflow: visible;
  height: auto !important;
}
.answer-ani{
  color: #7786cb;
}
</style>
