<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="笔记"></Title>
      <div class="content hidden">
        <div class="text_wrapper">
          <textarea placeholder="请输入填写笔记内容"></textarea>
        </div>
        <div class="flex04 btn_wrapper">
          <Button type="primary" size="large" class="btn_border" ghost>取消</Button>
          <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px">确认</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 820px;
  margin: 27px 0 0 27px;
}

.text_wrapper {
  padding: 14px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d4d4d4;

  textarea {
    width: 100%;
    height: 300px;
    resize: none;
  }
}

.btn_wrapper {
  margin: 24px 0 47px;
}
</style>
