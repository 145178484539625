<template>
  <div class="box flex01 bg_fff">
    <div class="content bg_fff">
      <img src="../../assets/image/a.jpg" class="logo_img" />
      <p class="font_16 bold_ flex01">零一教育习题平台</p>
      <div class="wrapper">
        <div class="flex02 list">
          <Icon type="md-person" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
          <Input :border="false" v-model="form.username" placeholder="请输入账号" clearable/>
        </div>
        <div class="flex02 list">
          <Icon type="md-lock" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px"/>
          <Input type="password" :border="false" v-model="form.password" placeholder="请输入密码" clearable/>
        </div>
        <div class="flex02 list">
          <Icon type="md-lock" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px"/>
          <Input type="password" :border="false" v-model="form.again" placeholder="请确认密码" clearable/>
        </div>
        <div class="flex02 list">
          <Icon type="md-mail" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px"/>
          <Input :border="false" v-model="form.code" placeholder="识别码" clearable />
        </div>
        <Button type="primary" size="large" class="btn_bg login_btn">注册</Button>
        <div class="flex04">
          <router-link to="/Login" class="font_14 color_355E7E" style="cursor: pointer">已有账号，去登录</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        again: "",
        code: "",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-top: 0;
}

.content {
  width: 400px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: #ccc 0 0 10px;
}

.wrapper {
  margin: 30px 0 50px;
  padding: 0 50px;
}

.logo_img {
  width: 90px;
  margin: 40px auto 0;
}

.list {
  padding: 2px 0;
  overflow: hidden;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.login_btn {
  margin: 10px 0 15px;
  cursor: pointer;
  height: 40px;
  width: 100%;
}
</style>
