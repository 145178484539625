<template>
  <div class="reach_wrapper" :class="{shake:ani}">
    <audio src="../../assets/image/mp3.mp3" ref="audio" v-if="ani"></audio>
    <div class="reach_content">
      <div class="reach_block" style="margin-bottom: 16px;" :class="[item.result === -2 ? 'orgen':item.result == 7 ? 'TLE':'',item.score < 1 ? 'on':'']" v-for="(item, index) in list" :key="index">
        <div>
          <p>#{{index+1}}</p>
          <p>
            <template v-if="item.result === -2">CE</template>
            <template v-else-if="item.result === 7">TLE</template>
            <template v-else-if="item.score < 1">WA</template>
            <template v-else>AC</template>
          </p>
          <p v-if="item.real_time">{{item.real_time}}ms/{{item.memory}}MB</p>
        </div>
      </div>
      <template v-if="ani">
        <div style="width: 100%; height: 1px;"></div>
        <div v-if="score != 100" style="padding: 30rpx;color: #666;">
          您的得分是{{score}}
        </div>
        <Button class="btn" @click="$emit('close_success')">返回</Button>
      </template>
      
    </div>
    
    <div class="zxb-flower reach_center" v-if="score == 100 && ani">
      <img src="../../assets/image/hua1.png" class="hua1" alt="">
      <img src="../../assets/image/hua2.png" class="hua2" alt="">
    </div>
    <div class="reach_center" style="display: none;" v-if="ani">
      <div class="reach_text">
        <div class="reach_text_content">
          <p>你通过了此题</p>
          <p>恭喜！</p>
        </div>
      </div>
      <img src="../../assets/image/c.gif" />
      <img src="../../assets/image/a.gif" />
    </div>
  </div>
</template>

<script>
export default {
  props:{
    list:{
      type:Array,
      default:[]
    },
    score:{
      type:Number,
      default:0
    },
    ani:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      voice:false
    };
  },
  mounted() {
    console.log('list',this.list)
    console.log('score',this.score)
    if(this.score === 100){
      setTimeout(()=>{
        // this.$refs.audio.muted = !this.voice
        this.$refs.audio.play()
      },500)
    }
  },
  methods: {
    play(){
      this.$refs.audio.muted = this.voice
      this.voice = !this.voice;
    }
  },
};
</script>

<style lang="less" scoped>
.voice{width: 30px;height: 30px;background-image: url(../../assets/image/voice.png);background-size: 100%;position: absolute;right: 0;top: -50px;z-index: 980; cursor: pointer;}
.voice::after{content: '';width: 110%;height: 2px;background-color: #999;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%) rotate(-40deg);}
.on.voice::after{display: none;}
.top.voice{top: -180px;right: 100px;}
.zxb-flower{
  animation: anim 0.4s 3;animation-delay:1s; width: 500px; height: 406px; position: relative;
  img{display: block;margin: 0 !important;opacity: 0;animation: hua1 0.4s forwards;animation-delay: 0.5s;}
  .hua1{width: 100%;}
  .hua2{position: absolute;left: 14.8%;top: 16.5%; animation-delay: 0.3s;width: 350px;height: 335px;}
}
@keyframes hua1{
  0%{transform: scale(0.1);opacity: 0;}
  100%{transform: scale(1);opacity: 1;}
}
@keyframes anim{
  0%{transform: translateX(-10px);}
  30%{transform: translateX(0);}
  70%{transform: translateX(-10px);}
  100%{transform: translateX(0);}
}
.reach_wrapper {
  display: flex;
  position: relative;
  align-items: flex-start;
  &.shake{
    height: 500px;
    align-items: center;
    padding: 0 40px;
    .reach_block{animation: shake 800ms ease-in-out;}
  }
}

.reach_content {
  display: flex;flex-wrap: wrap;position: relative; max-width: 800px; width: 100%; position: relative;
  .btn{position: absolute; left: 0; top: 100%; left: 40px; margin-top: 30px;}
  
  .reach_block {
    color: #fff;
    padding: 8px;
    width: 120px;
    margin: 0 10px 10px 0;
    text-align: center;
    background-color: #32cd32;
    &.on{background-color: red;}
    &.orgen{background-color: orange;}
    &.TLE{background-color: #052242;}
    p {
      &:first-child {
        text-align: left;
      }

      &:nth-child(2) {
        font-size: 20px;
        margin: 8px 0;
      }

      &:last-child {
        margin-bottom: 14px;
      }
    }
  }
}

@keyframes shake {
  /* 水平抖动，核心代码 */
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(+2px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(+4px, 0, 0);
  }

  50% {
    transform: translate3d(-4px, 0, 0);
  }
}

.reach_center {
  
  img {
    width: 65%;
    margin: 0 auto;

    &:last-child {
      margin-top: -500px;
    }
  }
}

.reach_text {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  .reach_text_content {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../../assets/image/q.png) no-repeat center;
    animation: change 3s;

    p {
      &:first-child {
        color: #ffa500;
        font-size: 20px;
      }

      &:last-child {
        color: #ffd700;
        font-size: 30px;
      }
    }
  }
}

@keyframes change {
  from {
    background-size: 10px 10px;
  }

  to {
    background-size: 100% 100%;
  }
}
</style>
