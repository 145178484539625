<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="统计图"></Title>
      <div class="content">
        <div class="search_top">
          <DatePicker
            :value="value2"
            format="yyyy-MM"
            type="daterange"
            placement="bottom-end"
            separator="            至            "
            placeholder="请选择"
            @on-change = "getDate"
            style="width: 350px; margin-right: 20px"
          ></DatePicker>
          <Button type="primary" size="large" class="btn_bg" @click="getList">查询</Button>
        </div>
        <div class="hidden">
          <div class="main_block" v-for="(item, index) in list" :key="index">
            <div class="flex02 main_title">
              <p></p>
              <p class="font_18 color_355E7E">{{item.studentName}}：积分：{{item.totalScore}}*{{item.studentWeight.weight}}+{{item.studentWeight.modifyScores}} = {{item.sum}}</p>
            </div>
            <div>
              <div :id="'echart'+index" v-if="item.scores.length > 0" style="width: 600px; height: 400px"></div>
              <!-- <div id="echart1" style="width: 600px; height: 400px" v-if="index === 0"></div>
              <div id="echart2" style="width: 600px; height: 400px" v-if="index === 1"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getListByClassIdApi} from "@/api/index.js";

export default {
  data() {
    return {
      value2: [],
      classId:null,
      start:'',
      end:'',
      list:[]
    };
  },
  mounted() {
    this.classId = this.$route.query.classId;
    console.log(this.classId);
    this.getList()
    // this.get_echart1();
    // this.get_echart2();
  },
  methods: {
    getList(){
      getListByClassIdApi({classId:this.classId,start:this.start,end:this.end}).then(res=>{
        this.list = res.map(m=>{
          let totalScore = Number(m.totalScore) || 0;
          let weight = 0;
          let modifyScores = 0
          if(m.studentWeight){
            weight = Number(m.studentWeight.weight) || 0;
            modifyScores = Number(m.studentWeight.modifyScores) || 0;
          }
          m.totalScore = totalScore
          m.sum = totalScore*weight + modifyScores;
          m.studentWeight.weight = weight;
          m.studentWeight.modifyScores = modifyScores
          return m
        });
        setTimeout(()=>{
          res.forEach((item,index) => {
          let idName = 'echart'+index;
          let titles = [];
          let seriesData = []
          let dataY = null
          item.scores.map((m,index) => {
            let name = m.subName;//'第'+(index+1)+'题'
            titles = titles.concat(name);
            let dataX = m.list.map(listItem=>{
              return listItem.num
            })
            dataY = m.list.map(listItem=>{
              return listItem.days
            })
            seriesData = seriesData.concat({
              name: name,
              type: "bar",
              stack: "one",
              barWidth : 30,//柱图宽度
              data: dataX,
            })
          });
          console.log('seriesData',seriesData,dataY)
          setTimeout(()=>{
            if(titles.length > 0){
              this.getEchart(idName,titles,seriesData,dataY)
            }
          },0)
        });
        },600)
      
      })
    },

    getEchart(idName,titles,seriesData,dataY){
      console.log(titles)
      var myChart = echarts.init(document.getElementById(idName));
      var option = {
        title: {
          text: "",
        },
        tooltip: {},
        color: ["#91CC75", "#FAC858", "#EE6666"],
        // legend: {
        //   data: titles, //['第一题','第二题'],
        // },
        xAxis: {
          data: dataY, //["2日", "3日", "4日", "5日", "6日"],
        },
        yAxis: {},
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
          },
        ], // 放大 缩小 比例
        series: seriesData,
      };

      myChart.setOption(option);
    },
    
    getDate(e){
      console.log(e)
      this.start = e[0];
      this.end = e[1]
    },
    
    get_echart1() {
      var myChart = echarts.init(document.getElementById("echart1"));

      var option = {
        title: {
          text: "",
        },
        tooltip: {},
        color: ["#91CC75", "#FAC858"],
        legend: {
          data: ["第一题", "第二题"],
        },
        xAxis: {
          data: ["2日", "3日", "4日", "5日", "6日"],
        },
        yAxis: {},
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
          },
        ], // 放大 缩小 比例
        series: [
          {
            name: "第一题",
            type: "bar",
            stack: "one",
            data: [20, 5, 60, 80, 100, 500],
          },
          {
            name: "第二题",
            type: "bar",
            stack: "one",
            data: [2, 5, 70, 80, 100, 500],
          },
        ],
      };

      myChart.setOption(option);
    },
    get_echart2() {
      var myChart = echarts.init(document.getElementById("echart2"));

      var option = {
        title: {
          text: "",
        },
        tooltip: {},
        color: ["#91CC75", "#FAC858"],
        legend: {
          data: ["第一题", "第二题"],
        },
        xAxis: {
          data: ["2日", "3日", "4日", "5日", "6日"],
        },
        yAxis: {},
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
          },
        ], // 放大 缩小 比例
        series: [
          {
            name: "第一题",
            type: "bar",
            stack: "one",
            data: [20, 5, 60, 80, 100, 500],
          },
          {
            name: "第二题",
            type: "bar",
            stack: "one",
            data: [2, 5, 70, 80, 100, 500],
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 0 0;
}

.search_top {
  padding: 20px 27px;
  border-bottom: 1px solid #ebeef5;
}

.main_block {
  overflow: hidden;
  padding: 0 27px;
  margin-bottom: 32px;
}

.main_title {
  margin: 22px 0;

  p:first-child {
    width: 8px;
    height: 8px;
    margin-right: 12px;
    background-color: #355e7e;
  }
}

.box /deep/ .ivu-input {
  height: 36px;
  color: #666;
  line-height: 36px;
  padding-left: 20px;
}

.box /deep/ .ivu-input-prefix i,
.box /deep/ .ivu-input-suffix i {
  height: 36px;
  line-height: 36px;
}
</style>
