<template>
  <div class="box flex01 bg_fff">
    <div class="content bg_fff">
      <img src="../../assets/image/logo3.png" class="logo_img" />
      <p class="font_16 bold_ flex01">零一教育习题平台</p>
      <dl class="login-list">
        <dd
          v-for="item in type_list"
          :class="[form.loginType == item.value ? 'on' : '']"
          @click="form.loginType = item.value"
        >
          <span>{{ item.label }}</span>
        </dd>
      </dl>
      <Form ref="form" :model="form" :rules="ruleInline">
        <div class="wrapper">
          <FormItem prop="userName">
            <div class="flex02 list">
              <Icon
                type="md-person"
                style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px"
              />
              <Input
                :border="false"
                v-model="form.userName"
                placeholder="请输入账号"
                clearable
              />
            </div>
          </FormItem>
          <FormItem prop="password">
            <div class="flex02 list">
              <Icon
                type="md-lock"
                style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px"
              />
              <Input
                type="password"
                :border="false"
                v-model="form.password"
                placeholder="请输入密码"
                clearable
              />
            </div>
          </FormItem>
          <!-- <div class="flex02 list">
            <Icon type="ios-school" style="font-size: 20px; color: #aaa; margin: 0 5px 0 10px" />
            <Select v-model="form.loginType">
              <Option v-for="item in type_list" :value="item.value" :key="item.value" >{{ item.label }}</Option>
            </Select>
          </div> -->
          
          <Button
            type="primary"
            size="large"
            class="btn_bg login_btn"
            @click="get_login('form')"
            >登录</Button
          >
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/index.js";

export default {
  data() {
    return {
      form: {
        userName: "",
        password: "",
        loginType: "1", // 0 学生 1 老师,2-超级管理员
      },
      ruleInline: {
        userName: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      type_list: [
        {
          value: 0,
          label: "学生登录",
        },
        {
          value: 1,
          label: "老师登录",
        },
        {
          value: 2,
          label: "管理员登录",
        },
      ],
    };
  },
  mounted() {
    localStorage.removeItem("userId");
    localStorage.removeItem("tokenName");
    localStorage.removeItem("loginType");
    localStorage.removeItem("tokenValue");
  },
  methods: {
    get_login(name) {
      console.log(this[name]);
      this.$refs[name].validate((valid) => {
        if (valid) {
          //
          login(this.form).then((res) => {
            localStorage.setItem("userId", res.detail.id);
            localStorage.setItem("tokenName", res.tokenInfo.tokenName);
            localStorage.setItem("loginType", this.form.loginType);
            localStorage.setItem("tokenValue", res.tokenInfo.tokenValue);
            let name = "";
            if (this.form.loginType === 0) {
              name = res.detail.studentName;
            } else if (this.form.loginType === 1) {
              name = res.detail.teacherName;
            } else if (this.form.loginType === 2) {
              name = res.detail.mangerName;
            }
            res.detail.name = name;
            localStorage.setItem("user", JSON.stringify(res.detail));
            this.$router.push({
              path: "/",
            });
            // this.$router.go(-1);
            localStorage.setItem("isFirstLogin", res.isFirstLogin.toString());
          });
        } else {
          //
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login-list {
  overflow: hidden;
  text-align: center;
  margin: 15px 50px -20px;
  line-height: 40px;
  border-bottom: 1px solid #ededed;
}

.login-list dd {
  float: left;
  width: 33%;
  color: #777;
  cursor: pointer;
  font-size: 14px;
}

.fr {
  float: right;
}

.login-list span {
  display: block;
  vertical-align: bottom;
}

.login-list .on span {
  position: relative;
  color: #333;
  font-weight: bold;
}

.login-list .on span::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #2d8cf0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.box {
  padding-top: 0;
}

.content {
  width: 400px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: #ccc 0 0 10px;
}

.wrapper {
  margin: 50px 50px 70px;
}

.logo_img {
  width: 90px;
  margin: 40px auto 10px;
}

.list {
  padding: 2px 0;
  overflow: hidden;
  // margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.login_btn {
  margin: 20px 0 0;
  cursor: pointer;
  height: 40px;
  width: 100%;
}

.box /deep/ .ivu-select-selection {
  border: none;
}

.box /deep/ .ivu-select-visible .ivu-select-selection {
  box-shadow: none;
}

.box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
.box
  /deep/
  .ivu-select-single
  .ivu-select-selection
  .ivu-select-selected-value {
  height: 32px;
  line-height: 32px;
}

.box /deep/ .ivu-form-item-error .ivu-input,
.box /deep/ .ivu-input {
  border: none;
  box-shadow: none;
  outline: none;
}
</style>
