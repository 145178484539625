<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="发送消息"></Title>
      <div class="content hidden">
        <div class="list_wrapper font_14 send_m1">
          <p class="label">接收人：</p>
          <div class="list_center">
            <Select placeholder="调取学员列表，展示学员姓名，可多选." v-model="formData.toUserIds" multiple size="large">
              <Option v-for="item in studentList" :value="item.id" :key="item.value">{{ item.studentName }}</Option>
            </Select>
          </div>
        </div>
        <div class="list_wrapper font_14 send_m1">
          <p class="label">标题：</p>
          <div class="list_center">
            <Input v-model="formData.title" placeholder="" style="width: 100%" />
          </div>
        </div>
        <div class="list_wrapper font_14">
          <p class="label">信息内容：</p>
          <div class="list_center hidden">
            <div id="fwb" ref="fwb"><div v-html="formData.content"></div></div>
            <Upload multiple :action="uploadUrl" accept="*" :on-success="uploadFile" :on-error="uploadErr" :on-remove="remove" style="margin-top:40px">
              <Button icon="ios-cloud-upload-outline">上传附件</Button>
            </Upload>
            <div class="flex04 btn_wrapper">
              <Button type="primary" size="large" class="btn_border" ghost @click="back()">取消</Button>
              <Button type="primary" size="large" class="btn_bg" style="margin-left: 18px" @click="submit">确认</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {leaveMessageSubmitApi,studentQueryApi,editorCreate,uploadApi1,getHtml,getTxt,showToast} from "@/api/index.js"
export default {
  data() {
    return {
      uploadUrl:uploadApi1(),
      cityList: [
        {
          value: "New York",
          label: "New York",
        },
        {
          value: "London",
          label: "London",
        },
        {
          value: "Sydney",
          label: "Sydney",
        },
        {
          value: "Ottawa",
          label: "Ottawa",
        },
        {
          value: "Paris",
          label: "Paris",
        },
        {
          value: "Canberra",
          label: "Canberra",
        },
      ],
      model10: [],
      studentList:[],
      formData:{
        content:'',
        title:'',
        appendix:'',
        type:'',
        fromUserId:localStorage.getItem('userId'),
        toUserIds:[]
      },
      appendix:[]
    };
  },
  mounted() {
    let loginType = localStorage.getItem('loginType');  //0-学生，1-老师
    let type = 2;
    if(loginType == 0){
      type = 3
    }else if(loginType == 1){
      type = 2
    }
    this.formData.type = type
    studentQueryApi({}).then(res=>{
      this.studentList = res
    })
    editorCreate(this,'fwb')
  },
  methods: {
    // 上传图片
    uploadFile(e){
      this.appendix = this.appendix.concat(e)
      console.log(this.appendix)
    },
    uploadErr(e){
      console.log(e)
    },
    submit(){
      let data = JSON.parse(JSON.stringify(this.formData));
      console.log(data)
      if(data.toUserIds.length === 0) return showToast(this,'请选择接收人');
      if(data.title.length === 0) return showToast(this,'标题不能为空');
      if(getTxt('fwb') === '\n') return showToast(this,'信息内容不能为空')
      data.appendix = this.appendix.map(m => {
        let item = {
          path:m.result,
          name:m.fileName
        }
        return m
      })
      data.content = getHtml('fwb')
      leaveMessageSubmitApi(data).then(res=>{
        showToast(this,'发送成功',1);
        this.back()
      })
    },
    remove(e){
      this.appendix = this.appendix.filter(n => n.result !=e.response.result)
    },
    back() {
      this.$router.go(-1); //返回上一层
    },  
  },
  
};
</script>

<style lang="less" scoped>
.list_wrapper {
  display: flex;

  .label {
    width: 108px;
    text-align: right;
    line-height: 40px;
  }

  .list_center {
    width: 820px;
  }
}

.send_m1 {
  margin: 18px 0 27px;
}

.btn_wrapper {
  margin: 24px 0 47px;
}

.box
  /deep/
  .ivu-select-large.ivu-select-multiple
  .ivu-select-selection
  .ivu-select-placeholder,
.box
  /deep/
  .ivu-select-large.ivu-select-multiple
  .ivu-select-selection
  .ivu-select-selected-value {
  font-size: 14px;
}

.box /deep/ .ivu-select-large.ivu-select-multiple .ivu-tag {
  font-size: 14px;
}

.box /deep/ .w-e-toolbar {
  border-radius: 4px 4px 0 0;
}

.box /deep/ .w-e-text-container {
  border-radius: 0 0 4px 4px;
}

.box /deep/ .ivu-select-dropdown {
  z-index: 99999;
}
</style>
