<template>
  <div class="head_wrapper">
    <div class="w_1440 head_content">
      <img src="../../assets/image/logo.png" class="logo" />
      <div class="head_right_wrapper font_14">
        <img src="../../assets/image/logo2.png" class="head_img" />
        <div>{{user.name}}您好</div>
        <div>
          <Dropdown @on-click="get_drop_click">
            <a href="javascript:void(0)" class="down_wrapper">
              <template v-if="dakaStatus != now">，您还未打卡</template>
              <Icon type="ios-arrow-down" style="color: #bcbdc2; margin-left: 5px;"></Icon>
            </a>
            <DropdownMenu slot="list">
              <div class="head_index_btn" v-if="dakaStatus != now">
                <Button type="primary" size="large" class="btn_border" ghost @click="daka()">打卡</Button>
              </div>
              <p class="head_index_des" v-if="loginType == 0">积分：{{getIntegral}}</p>
              <DropdownItem name="1" v-if="loginType != 0">管理后台</DropdownItem>
              <DropdownItem name="2"><router-link to="Fwb">使用说明</router-link></DropdownItem>
              <DropdownItem name="4">修改密码</DropdownItem>
              <DropdownItem name="3">退出登录</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <router-link to="Info">
          <div class="inform_wrapper">
            <Icon type="ios-notifications-outline" size="22" color="#666" />
            <p class="inform_num flex01">{{infoNums}}</p>
          </div>
        </router-link>
      </div>
    </div>
    <Modal footer-hide v-model="show" class-name="vertical-center-modal" title="今日运势(仅参考)">
      <div class="hidden">
        <div class="flex01 modal_head_title">
          <img src="../../assets/image/o@2x.png" />
          <p>中平</p>
          <img src="../../assets/image/o@2x.png" />
        </div>
        <div class="modal_head_content">
          <div style="margin-right: 37px">
            <div class="modal_head_block flex05">
              <p class="modal_head_block_left">宜</p>
              <div class="modal_head_block_right">
                <p class="font_14">学习柯学</p>
                <p class="font_12">柯朵莉太可爱了</p>
              </div>
            </div>
            <div class="modal_head_block flex05">
              <p class="modal_head_block_left">宜</p>
              <div class="modal_head_block_right">
                <p class="font_14">吃饭</p>
                <p class="font_12">人是铁饭是钢</p>
              </div>
            </div>
          </div>
          <div>
            <div class="modal_head_block active flex05">
              <p class="modal_head_block_left">忌</p>
              <div class="modal_head_block_right">
                <p class="font_14">扶老奶奶过马路</p>
                <p class="font_12">会被讹</p>
              </div>
            </div>
            <div class="modal_head_block active flex05">
              <p class="modal_head_block_left">忌</p>
              <div class="modal_head_block_right">
                <p class="font_14">继续完成WA的题</p>
                <p class="font_12">然而变成了TLE</p>
              </div>
            </div>
          </div>
        </div>
        <div class="font_14 flex01 modal_head_foot">
          你已经在零一连续打卡了 {{dakaDays}} 天
        </div>
      </div>
    </Modal>
  </div>
</template>


<script>
import { clockInApi,getIntegralApi,showToast,leaveMessageApi } from "@/api/index.js";
export default {
  data() {
    return {
      show: false,
      user:localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
      loginType:localStorage.getItem('loginType'),
      dakaStatus: localStorage.getItem('dakaStatus') || 0, //0-没有打卡，1-已打卡
      dakaDays:localStorage.getItem('dakaDays') || 0,
      con:'',
      getIntegral:'', //公式
      now:new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate(),
    };
  },
  created() {
    this.getCon()
  },
  watch: {
    $route(to, from) {
      this.getCon()
    },
  },
  computed:{
    infoNums() {
      const infoNums = this.$store.state.infoNums;
			console.log('infoNums====>',infoNums)
      return infoNums ? infoNums : 0;
    },
  },
  methods: {
    getCon(){
      leaveMessageApi({type:2}).then(res=>{
        this.$store.state.infoNums = res.filter(n => n.status == 0).length;
      })
      let user = localStorage.getItem('user');
      if(user && this.loginType == 0){
        getIntegralApi({id:JSON.parse(user).id}).then(res=>{
        this.con = res;
        let sum = (Number(res.totalScore) || 1)*Number(res.weight) + (Number(res.modifyScores) || 0)
        this.getIntegral = (Number(res.totalScore) || 1)+'*'+res.weight+'+'+res.modifyScores +'='+sum
      })
      }
      
    },
    daka(){
      let user = JSON.parse(localStorage.getItem('user'));
      clockInApi({studentId:user.id}).then(res=>{
        let dakaStatus = new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()
        this.dakaStatus = dakaStatus;
        this.dakaDays = res
        localStorage.setItem('dakaStatus',dakaStatus)
        localStorage.setItem('dakaDays',this.dakaDays)
        setTimeout(()=>{
          this.show = true;
        },200)
      })
    },
    get_drop_click(e) {
      console.log(e);
      if(e === '1'){
        window.open('http://zeroadmin.maoln.com');
      }else if(e === '3'){
        this.$router.push({
          path: "/Login",
        });
      }else if(e === '4'){
        this.$router.push({
          path: "/Password",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.head_wrapper {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  background-color: #fff;
  box-shadow: 0px 0px 15px #f4f4f4;

  .head_content {
    display: flex;
    justify-content: space-between;
  }
}

.logo {
  height: 30px;
  margin:20px 0;
}

.head_right_wrapper {
  display: flex;
  align-items: center;
}

.head_img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 13px;
}

.down_wrapper {
  color: #333;
}

.inform_wrapper {
  margin-left: 20px;
  position: relative;

  .inform_num {
    top: -3px;
    right: -3px;
    color: #fff;
    width: 14px;
    height: 14px;
    font-size: 8px;
    position: absolute;
    border-radius: 50%;
    background-color: #f76767;
  }
}

.head_index_btn {
  margin: 11px 0 0 0;
  text-align: center;

  /deep/ .btn_border {
    min-width: 54px;
    height: 24px;
    font-size: 12px;
  }
}

.head_index_des {
  min-width: 130px;
  padding: 0 15px;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  color: #666;
  margin: 8px 0;
}

/deep/ .ivu-dropdown-item {
  text-align: center;
}

/deep/ .vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}

.modal_head_title {
  color: #4a964f;
  font-size: 40px;
  line-height: 52px;
  font-weight: bold;
  margin-bottom: 23px;

  img {
    height: 44px;
    margin: 0 5px;
  }
}

.modal_head_content {
  display: flex;
  justify-content: center;
}

.modal_head_block {
  &:first-child {
    margin-bottom: 17px;
  }

  &.active {
    .modal_head_block_left {
      background-color: #bf3f38;
    }
  }

  .modal_head_block_left {
    color: #fff;
    width: 24px;
    height: 24px;
    display: flex;
    font-size: 14px;
    line-height: 19px;
    border-radius: 50%;
    margin-right: 7px;
    align-items: center;
    justify-content: center;
    background-color: #4a964f;
  }

  .modal_head_block_right {
    p {
      &:first-child {
        line-height: 24px;
      }

      &:last-child {
        color: #999;
        margin: 1px 0 0 0;
      }
    }
  }
}

.modal_head_foot {
  margin: 30px 0 0;
}
</style>
