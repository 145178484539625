<template>
  <div class="box">
		<div class="w_1440 location">
		  <Breadcrumb>
		    <BreadcrumbItem to="/">首页</BreadcrumbItem>
		    <BreadcrumbItem :to="'Detail?classId='+classId">班级详情</BreadcrumbItem>
		    <BreadcrumbItem>布置作业</BreadcrumbItem>
		  </Breadcrumb>
		</div>
    <div class="w_1440 bg_fff w_m20">
      <Title name="布置作业"></Title>
      <div class="content" style="min-height: 600px;">
				<Form :model="formData" :label-width="100" style="width: 500px;">
					<FormItem label="班级">{{courseName}}</FormItem>
					<FormItem label="课节">
						<Select v-model="formData.courseScheduleId" placeholder="选择课节">
							<Option v-for="item in courseList" :value="item.id" :key="item.id">{{item.orders}}({{item.courseDate}})</Option>
						</Select>
					</FormItem>
					<FormItem label="选择习题组">
						<Select v-model="formData.problemGroupIdList" multiple placeholder="选择习题组">
							<Option v-for="item in problemGroupList" :value="item.id" :key="item.id">{{item.problemGroupName}}</Option>
						</Select>
					</FormItem>
					<FormItem label="">
						<div class="layout_m2">
						  <Button type="primary" size="large" class="btn_border" ghost style="margin-right: 18px" @click="$router.go(-1)" >取消</Button>
						  <Button type="primary" size="large" class="btn_bg" @click="get_submit">发布作业</Button>
						</div>
					</FormItem>
				</Form>
      </div>
    </div>
		
		
		<Modal title="提示" v-model="workShow" width="300" ok-text="知道了">
			<p slot="header" style="text-align:center">提示</p>
			<p style="text-align: center;">作业布置成功，一分钟后下发</p>
			<div slot="footer" style="text-align: center;">
				<button type="button" class="ivu-btn ivu-btn-primary" @click="getWorkShow()">知道了</button>
			</div>
		</Modal>
  </div>
</template>

<script>
import {
  classJobSaveApi,
  queryProblemSetsByGroupId,
	queryCourseScheduleListApi,problemGroupApi
} from "@/api/index.js";

export default {
  data() {
    return {
			workShow: false,
      classId: '',
      exercises_id: [],
      group_list: [], // 习题组
      exercises_name: "", // 已选择习题组名称
      columns: [
        {
          width: 60,
          type: "selection",
          align: "center",
          fixed: "left",
          className: "table-head-style",
        },
        {
          width: 250,
          title: "习题组名称",
          key: "problemName",
        },
      ],
      data_: [],
			courseList:[], //课节列表
			problemGroupList:[], //习题组列表
			formData:{
				classId:this.$route.query.classId, //班级id
				courseScheduleId:'', //课程id
				problemGroupIdList:'', //习题组id
			},
			courseName:''
    };
  },
  mounted() {
		this.courseName = this.$route.query.className
		this.classId = this.$route.query.classId
    this.get_list_data();
  },
  methods: {
		getWorkShow(){
			this.$router.go(-1);
			this.workShow = false;
		},
    // 习题组
    get_list_data() {
			problemGroupApi({classId:this.classId}).then(res=>{
				this.problemGroupList = res
			})
			queryCourseScheduleListApi({courseId:this.classId}).then(res=>{
				this.courseList = res
			})
		},
    // 习题
    get_list_xiti(k) {
      queryProblemSetsByGroupId({
        problemGroupId: this.group_list[k].problemGroupId,
      }).then((result) => {
        if (result.length > 0) {
          this.group_list[k].length = result.length;

          result.forEach((n, k_) => {
            let a = this.$publicFun.letter()[k_];
            this.group_list[k][a] = n.title;
          });
        }

        if (k === this.group_list.length - 1) {
          this.get_data_dispose();
        }
      });
    },
    // 表内数据处理
    get_data_dispose() {
      let max = null; // 最大值
      let num_list = [];

      this.group_list.forEach((n, k) => {
        num_list.push(n.length);
      });

      max = Math.max.apply(-Infinity, num_list);

      for (let i = 1; i < max + 1; i++) {
        this.columns.push({
          width: 250,
          title: "题目名称" + i,
          key: this.$publicFun.letter()[i - 1],
        });
      }

      // 最后一行补齐剩下的宽度
      this.columns.push({
        title: " ",
        className: "table-head-style",
      });

      setTimeout(() => {
        this.data_ = this.group_list;
      }, 100);
    },
    // 勾选
    get_select(e) {
      let name_ = [];

      this.exercises_id = [];

      if (e.length > 0) {
        e.forEach((n, k) => {
          name_.push(n.problemName);
          this.exercises_id.push(n.problemGroupId);
        });
      }

      this.exercises_name = name_.toString();
    },
    // 发布作业
    get_submit() {
			let data = this.formData
      if (data.courseScheduleId.length === 0) return this.$Message.error("请选择课节");
      if (data.problemGroupIdList.length === 0) return this.$Message.error("请选择习题组");
      classJobSaveApi(data).then((res) => {
        // this.$Message.success("成功");
				this.workShow = true;
    //     setTimeout(()=>{
				// 	this.$router.go(-1);
				// 	this.workShow = false;
				// },2000)
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  overflow: hidden;
  margin: 20px 0 0 0;
}

.layout_m1 {
  padding: 0 22px;
  margin: 26px 0 12px;
}

.layout_m2 {
  margin: 0 0 49px;
}

/deep/ .ivu-table th.table-head-style {
  padding: 13px 0;
  background-color: #f8f8f9;
}
</style>
