<template>
	<div class="box">
		<Spin fix v-if="is_sign">
			<Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
			<div>Loading</div>
		</Spin>
		<div class="w_1440 bg_fff w_m20">
			<Title name="签到统计"></Title>
			<div>
				<div class="detail_top font_14">
					<div class="detail_block">
						<p>班级名称：</p>
						<p>{{ essential_info.courseName }}</p>
					</div>
					<div class="detail_block">
						<p>班级类型：</p>
						<p>{{ essential_info.classTypeName }}</p>
					</div>
					<div class="detail_block">
						<p>教师：</p>
						<p>{{ essential_info.teacherName }}</p>
					</div>
					<div class="detail_block">
						<p>年度：</p>
						<p>{{ essential_info.year }}</p>
					</div>
					<div class="detail_block">
						<p>班级地点：</p>
						<p>{{ essential_info.classroomName }}</p>
					</div>
					<div class="detail_block">
						<p>验证码：</p>
						<p>{{ essential_info.captcha }}</p>
					</div>
				</div>
				<div style="padding: 0 14px" v-if="is_load">
					<Table :columns="columns1" :data="data1">
						<template slot-scope="{ row, index }" :slot="'course' + index1" v-for="(item, index1) in data1[0].array">
							<div v-for="(itemChild, indexChild) in row.array">
								<div>
									<span :class="{'color_355E7E': index1 == indexChild && itemChild.status == '未签到', 'pointer_': index1 == indexChild && (itemChild.status == '未签到' || itemChild.status == '已签到')}"
									 @click="get_sign(itemChild, index1)">
										{{index1 == indexChild?itemChild.status: ''}}
									</span>
								</div>
							</div>
						</template>
					</Table>
				</div>
			</div>
		</div>
		<Modal v-model="modal1" title="签到" @on-ok="get_modal_ok">
			<div class="font_14">
				<div class="flex02 modal_list">
					<p class="modal_label">签到班级(课程)：</p>
					<div class="modal_wflex">
						<p>{{modal_info.courseName}}</p>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到课节：</p>
					<div class="modal_wflex">
						<p>{{modal_info.orders}}</p>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到学员：</p>
					<div class="modal_wflex">
						<p>{{modal_info.studentName}}</p>
					</div>
				</div>
				<div class="flex02 modal_list">
					<p class="modal_label">签到状态：</p>
					<div class="modal_wflex flex02">
						<img src="../../assets/image/yuan.png" class="modal_circle" />
						<div>签到</div>
					</div>
				</div>
			</div>
		</Modal>
		<Modal v-model="modalCancel" title="提示" @on-ok="get_cancel_ok">
			确定取消签到吗？
		</Modal>
	</div>
</template>

<script>
	// loginType 0-学生 1-老师 2-超级管理员
	import {
		courseSignQuery,
		teacherCallRoll,
		cancelSignIn,
		signIn
	} from "@/api/mxy.js";

	export default {
		data() {
			return {
				cancelId: '',
				is_sign: false,
				data1: [],
				modal1: false,
				loginType: '',
				is_load: false,
				essential_info: {},
				modal_info: {},
				courseId: '', //课程id
				modalCancel: false,
				columns1: [{
						title: "排名",
						key: "index",
            width:80,
            "fixed": "left",
					},
					{
						title: "学员名称",
						key: "studentName",
            width:100,
            "fixed": "left",
					}
				],
				submit_info: {
					studentId: '',
					courseId: '',
					courseSchedulId: ''
				}
			};
		},
		mounted() {
			this.loginType = localStorage.getItem('loginType')
			this.courseId = this.$route.query.id;
			this.get_courseSignQuery();
		},
		methods: {
			get_courseSignQuery() {
				courseSignQuery({
					courseId: this.courseId
				}).then(res => {
					this.essential_info = res;

					if (res.list.length > 0) {
						res.list.forEach((n, k) => {
              this.data1.push({
              	index: k + 1,
              	studentId: n.student.studentId,
              	studentName: n.student.studentName + '(' + n.student.level + ')',
              	array: n.list
              })
							
						})

						res.list[0].list.forEach((n, k) => {
							this.columns1.push({
								title: n.orders + '(' + n.classStartTime + '-' + n.classEndTime + ')',
								slot: 'course' + k,
                "width": 150,
							})
						})
					}

					this.is_load = true;
				})
			},
			// 签到
			get_sign(item, index) {
				if (item.status == '未签到') {
					if (this.loginType == 0 && (item.studentId != localStorage.getItem('userId'))) return this.$Message.error('学生不可代签');

					this.modal1 = true;
					this.modal_info = {
						courseName: this.essential_info.courseName,
						orders: item.orders + '(' + item.classStartTime + '-' + item.classEndTime + ')',
						studentName: item.studentName
					}

					this.submit_info = {
						studentId: item.studentId,
						courseId: item.courseId,
						courseSchedulId: item.courseSchedulId
					}
				}

				if (item.status == '已签到') {
					if (this.loginType == 0) return this.$Message.error('只有老师可以取消签到');

					this.cancelId = item.coueseSignId;
					this.modalCancel = true;
				}
			},
			get_modal_ok() {
				this.is_sign = true;

				if (this.loginType == 0) {
					// 学生自己签到
					signIn({
						courseId: this.submit_info.courseId,
						courseSchedulId: this.submit_info.courseSchedulId
					}).then(res => {
						this.$Message.success('签到成功');
						this.get_int();
					})
				} else {
					teacherCallRoll(this.submit_info).then(res => {
						this.$Message.success('签到成功');
						this.get_int();
					})
				}
			},
			get_cancel_ok() {
				this.is_sign = true;

				cancelSignIn({
					id: this.cancelId
				}).then(res => {
					this.$Message.success('已取消点名');
					this.get_int();
				})
			},
			get_int() {
				this.data1 = [];
				this.columns1 = [{
						title: "排名",
						key: "index",
					},
					{
						title: "学员名称",
						key: "studentName",
					}
				];
				this.is_sign = false;
				this.get_courseSignQuery();
			}
		},
	};
</script>

<style lang="less" scoped>
	.demo-spin-icon-load {
		animation: ani-demo-spin 1s linear infinite;
	}

	.pointer_ {
		cursor: pointer;
	}

	.top_wrapper {
		display: flex;
		align-items: center;
		padding: 13px 130px 13px 27px;
	}

	.operate_wrapper {
		p {
			cursor: pointer;
			margin-right: 22px;
		}
	}

	.top_left {
		width: 120px;
		margin-right: 20px;
	}

	.box /deep/ .ivu-select-single .ivu-select-selection {
		height: 36px;
	}

	.detail_top {
		display: flex;
		flex-wrap: wrap;
		padding: 16px 0;
		border-bottom: 1px solid #ebeef5;
	}

	.detail_block {
		flex: 0 0 25%;
		display: flex;
		margin: 10px 0;
		padding-left: 27px;
		box-sizing: border-box;
	}

	.box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-placeholder,
	.box /deep/ .ivu-select-single .ivu-select-selection .ivu-select-selected-value {
		height: 34px;
		line-height: 34px;
	}

	.modal_list {
		margin: 20px 0;
	}

	.modal_label {
		width: 160px;
	}

	.modal_wflex {
		flex: 1;

		.modal_circle {
			width: 16px;
			height: 16px;
			margin-right: 5px;
		}
	}
  /deep/th .ivu-table-cell{font-size: 12px;font-weight: normal;}
  /deep/ .ivu-table-cell{text-align: center;font-size: 12px;}
</style>
