import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stroes";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import Title from "./components/Title/index.vue";
import Public from "./assets/js/public.js";
import VueClipboard from "vue-clipboard2";
import VueTinymce from '@packy-tang/vue-tinymce'
/*** 注： 5.3.x版本需要额外引进图标，没有所有按钮就会显示not found *****/
import 'tinymce/icons/default/icons'
import './assets/tinymce/zh_CN.js'
Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)  


import iEditor from 'iview-editor';

// 使用 css
// import 'iview/dist/styles/iview.css';
// import 'iview-editor/dist/i-editor.css';

// 使用插件
Vue.use(iEditor);




Vue.config.productionTip = false;
Vue.prototype.$publicFun = Public;
Vue.use(ViewUI);
Vue.use(VueClipboard);
Vue.component("Title", Title);


new Vue({
  router,
  store,



  render: (h) => h(App),
}).$mount("#app");
