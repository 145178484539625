/* 异步方法，执行mutations中方法 */

export default {
  // 变更语言
  infoNums({ commit }, locale) {
    localStorage.setItem('infoNums', locale);
    commit('SAVE_INFO', locale);
  },
  // 存储用户信息
  setUserInfo({commit}, userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    commit('SAVE_USER_INFO', userInfo);
  },
}

// this.$store.dispatch('setToken', toekn);