<template>
  <div class="box">
    <div class="w_1440 bg_fff w_m20">
      <Title name="使用说明"></Title>
      <div class="content font_14" v-html="content.content"></div>
    </div>
  </div>
</template>

<script>
import { getConfByKeyApi} from "@/api/index.js";
export default {
  data() {
    return {
      content:null
    };
  },
  mounted() {
    getConfByKeyApi({key:'INSTRUCTIONS'}).then(res=>{
      if(res){
        this.content = res
      }
    })
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 27px;
}
</style>
